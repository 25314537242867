import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'info',
})
export class InfoComponent extends AppComponentBase {
    isInAboutUsPage:boolean=false;

    constructor(
        injector: Injector,
        private _router: Router,

    ) {
        super(injector);
        this.isInAboutUsPage = (this._router.url.includes("aboutOurshow") || this._router.url.includes("aboutMedia"));

    }

}
