import { Injectable } from '@angular/core';
import { AbpHttpConfigurationService, LogService, MessageService } from 'abp-ng2-module';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ZeroTemplateHttpConfigurationService extends AbpHttpConfigurationService {
    constructor(messageService: MessageService, logService: LogService, private _route: Router) {
        super(messageService, logService);
    }

    // Override handleUnAuthorizedRequest so it doesn't refresh the page during failed login attempts.
    handleUnAuthorizedRequest(messagePromise: any, targetUrl?: string) {
        if (this.shouldIgnoreUnAuthorizedRequest()) {
            return;
        }

        const self = this;

        if (messagePromise) {
            messagePromise.done(() => {
                this.handleTargetUrl(targetUrl || '/');
            });
        } else {
            self.handleTargetUrl(targetUrl || '/');
        }
    }

    private _ignoreUnAuthorizedUrls = [
        '/account/login',
        '/account/easy-login',
        '/account/session-locked',
        '/app/home'
    ];
    private shouldIgnoreUnAuthorizedRequest(): boolean{
        const url = this._route.url;
        if (!url){
            return false;
        }

        for (var i = 0; i< this._ignoreUnAuthorizedUrls.length; ++i){
            const ignoreUnAuthorizedUrl = this._ignoreUnAuthorizedUrls[i];
            if (url.startsWith(ignoreUnAuthorizedUrl)){
                return true;
            } 
        }
        return false;
    }
}
