import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'privacy-policy',

})
export class PrivacyPolicyComponent extends AppComponentBase {

    constructor(

        injector: Injector,
    ) {
        super(injector);
    }

    goToLink(url): void {
        window.open(
            url,
            '_blank'
        );
    }

    scrollToForm(section): void {
        document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }
}
