export class GameDto {
    id: number;
    title: string;
    imageUrl: string;
    videoUrl: string;
    showVideo: boolean
}

export class GamesData {
    static games: GameDto[] = [{
        id: 0,
        title: "Say it",
        imageUrl: "assets/common/images/games/say_it.png",
        videoUrl: "assets/videos/games/gameplay_dummy_video1.mp4",
        showVideo: false
    },
    {
        id: 1,
        title: "Connect",
        imageUrl: "assets/common/images/games/connect.png",
        videoUrl: "assets/videos/games/gameplay_dummy_video2.mp4",
        showVideo: false
    },
    {
        id: 2,
        title: "Spell It",
        imageUrl: "assets/common/images/games/spell_it.png",
        videoUrl: "assets/videos/games/gameplay_dummy_video3.mp4",
        showVideo: false
    },]
}
