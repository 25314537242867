<header class="home_header">
  <div class="row home_header-container">
    <div id="hello-moving" class="home_header-box">
      <h1 id="hello">{{ 'Hello'}}</h1>
      <div class="moving-text">
        <moving-text id="moving-text"></moving-text>
      </div>
      <img *ngIf="isUserGuest" [routerLink]="['/account/easy-register']" class="download-button" src="/assets/common/images/header/join_now.png">
    </div>
    <div id="forHeaderImage" class="home_header-box">
      <img class="home_header-image" src="/assets/common/images/header/hello_friends_kuzu.png">
    </div>
  </div>
</header>