<main [style.background-color]="color" id="forMain" class="main-container">
  <siteHeader title="Learn More" imgSrc="/assets/common/images/learnMore/MillieWithFlower.png" backGroundColor="#D03E97"
    color="#F8E031"></siteHeader>
  <div class="heading">
    {{_ovalName$ | async}}
  </div>
  <div class="heading-episode-name">
    {{_episodeName$ | async}}
  </div>
  <div class="files">
    <div [class.file-name-main-padding-bottom]="fileWithLevel?.isNextFileHasDifferentDifficulty"
      *ngFor="let fileWithLevel of _filesWithLevel$ | async" class="file-name-main">
      <div [style.backgroundColor]="fileWithLevel?.difficultyLevel?.color" class="icon">
        {{fileWithLevel?.difficultyLevel?.name}}
      </div>
      <div class="fileName-div">
        <a (click)="openFile(fileWithLevel?.file.id)" class="fileName" target="_blank"> {{fileWithLevel?.file.name}}</a>
      </div>
    </div>
  </div>
</main>
<site-footer color="#b682eb"></site-footer>