import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SessionService {
  isSidebarVisible: boolean;
  currentUserToken: string = "";
  currentUserTokenValue: Subject<string> = new Subject<string>();

  constructor() {
    this.currentUserTokenValue.subscribe((value) => {
      this.currentUserToken = value
    });
  }
  
  setData(data: string) {
    this.currentUserTokenValue.next(data);
  }

  getData(): string {
    var current = localStorage.getItem("WordPlay/abpzerotemplate_local_storage/enc_auth_token");
    if (current !== null && current !== undefined) {
      return current;
    }
    return this.currentUserToken;
  }
}