<div class="navbar">
  <div class="flex-container">
    <div routerLink="/app/home" class="navbar-logo_section">
      <a>
        <img class="nav-logo" src="/assets/common/images/header/logo.png" alt="logo" />
      </a>
    </div>
    <div class="navbar_navigation-section">
      <ul class="nav-list" id={{myLinksId}}>
        <li>
          <a routerLink="/app/episodes" routerLinkActive="active">
            <!-- {{ 'OurShow' | localize }} -->
            {{ 'Our Show!'}}
          </a>
        </li>
        <li>
          <a routerLink="/app/games" routerLinkActive="active">
            <!-- {{ 'Games' | localize }} -->
            {{ 'Games'}}
          </a>
        </li>
        <li>
          <a routerLink="/app/learn-more" routerLinkActive="active">
            <!-- {{ 'LearnMore' | localize }} -->
            {{ 'Learn More'}}
          </a>
        </li>
        <li>
          <a routerLink="/app/info/aboutUs" routerLinkActive="active">
            <!-- {{ 'AboutUs' | localize }} -->
            {{ 'About Us'}}

          </a>
        </li>
        <li>
          <a routerLink="/app/info/contact" routerLinkActive="active">
            <!-- {{ 'Contact' | localize }} -->
            {{ 'Contact'}}
          </a>
        </li>
      </ul>
    </div>
    <div class="navbar_functionality-section">
      <div id="forFlag" >
        <language-picker-local style="position: relative;;"></language-picker-local>
      </div>
      <div id="forLogOut" class="nabar-functionality_element">
        <a *ngIf="isUserLoggedIn" id="logOut" href="javascript:;" (click)="logout()" class="menu-link px-5">
          <!-- <span>{{ 'Logout' | localize }}</span> -->
          <span>Log Out</span>
          <span id="userName">{{userName}}</span>
        </a>
        <a style="margin-bottom: 12px;" *ngIf="!isUserLoggedIn" id="logOut" href="javascript:;"
          routerLink="/account/easy-login" class="menu-link px-5">
          <span>Log In</span>
        </a>
      </div>
      <div class="navbar-burger-div" class="nabar-functionality_element">
        <img class="navbar-burger" (click)="toggleBurger()" src="/assets/common/images/icons/burger.png">
      </div>
    </div>
  </div>
</div>