import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './home-header.component.html',
    styleUrls: ['./home-header.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'home-header',
})
export class HomeHeaderComponent extends AppComponentBase {

    isUserGuest:boolean=true;
    dataText: string[] = [
        'Come and play with us',
        'Eja të lozësh me ne',
        'Zagraj z nami!',
        'Ven y juega con nosotros',
        'Venez et jouez avec nous',
        'მოდი და ითამაშეთ ჩვენთან ერთად',
        '来和我们一起玩',
        '是非、私たちと遊んでください',
        'Hãy đến và chơi với chúng tôi',
    ];

    constructor(injector: Injector) {
        super(injector);

        this.isUserGuest = !(abp.session.userId > 0);
    }
}
