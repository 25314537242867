<main id="mainOfEpisodePage">
  <siteHeader title="{{_episodeTitle$ | async}}" imgSrc="/assets/common/images/header/geordge_01.png"
    backGroundColor="#90D00E" color="#ffffff"></siteHeader>
  <div class="episodePanelVideo">
    <div id="VideoPlayer" class="animation_panel">
      <div class="animation_panel-player" *ngIf="episodeMedia$ | async as episodeMedia"
        [class.media-loading]="isMediaLoading">
        <vg-player id="video" #player (onPlayerReady)="onPlayerReady($event)">
        <vg-overlay-play (click)="playPauseMedia()"></vg-overlay-play>
          <vg-buffering vgFor="videoElement"></vg-buffering>

          <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>

          <vg-controls>
            <div class="media-play-pause">
              <div *ngIf="isMediaPlaying" (click)="pauseMedia()" tabindex="0" role="button" class="icon vg-icon-pause"
                aria-label="pause" aria-valuetext="playing">
              </div>
              <div *ngIf="!isMediaPlaying" (click)="playMedia()" tabindex="0" role="button"
                class="icon vg-icon-play_arrow" aria-label="play" aria-valuetext="paused">
              </div>
            </div>

            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>

            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

            <vg-mute></vg-mute>
            <vg-volume></vg-volume>

            <vg-fullscreen></vg-fullscreen>
          </vg-controls>

          <!--video tag should be first of all medias as first media used for full screen-->
          <video #videoElement id="videoElement"
                 [vgMedia]="videoElement"
                 [vgMaster]="true"
                 (ended)="mediaEnded()"
                 [muted]="isVideoMuted"
                 on-ended="onVideoEnd()"
                 style="width: 100%;" crossorigin playsinline>
          </video>
          <audio #audioElement id="audioElement"
                 [vgMedia]="audioElement"
                 (ended)="mediaEnded()"
                 [muted]="isAudioMuted" crossorigin playsinline>
          </audio>
        </vg-player>
      </div>
      <div *ngIf="_currentEpisode$ | async as currentEpisode" class="animation_panel-buttons">
        <div (click)="openFile(currentEpisode?.scriptContent)" id="animation_panel-button-new1"
          class="animation_panel-button-new">
          <div class="animation_panel-button-firstHalf">

          </div>
          <div class="animation_panel-button-secondHalf">
            Read the script
          </div>
          <div class="animation_panel-button-thirdHalf">
            <img src="/assets/common/images/episode/puzzle-icon.png">
          </div>
        </div>
        <div (click)="openFile(currentEpisode?.vocabularyContent)" id="animation_panel-button-new2"
          class="animation_panel-button-new">
          <div class="animation_panel-button-firstHalf">
          </div>
          <div class="animation_panel-button-secondHalf">
            Read the words
          </div>
          <div class="animation_panel-button-thirdHalf">
            <img src="/assets/common/images/episode/abc-icon.png">
          </div>
        </div>
        <div routerLink="/app/games" id="animation_panel-button-new3" class="animation_panel-button-new">
          <div class="animation_panel-button-firstHalf">
          </div>
          <div class="animation_panel-button-secondHalf">
            Play a game
          </div>
          <div class="animation_panel-button-thirdHalf">
            <img src="/assets/common/images/episode/note-icon.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
