<main [style.background-color]="color" id="forMainLM2" class="main-container">
    <siteHeader title="Learn More" imgSrc="/assets/common/images/learnMore/MillieWithFlower.png"
        backGroundColor="#D03E97" color="#F8E031"></siteHeader>
    <div class="LM2-body">
        <img class="shadow" src="/assets/common/images/ovals/LM2_background_shadow.png" alt="">
        <div class="LM2-oval-episode row">
            <div *ngIf="_ovalName$ | async as ovalName" class="LM2-oval col-3">
                <oval color="#A3EBEF" borderColor="#3EB5C9" textColor="black" description="{{ovalName}}"></oval>
            </div>
            <div class="LM2-episode col-5">
                <h1>{{episodeName}}
                </h1>
            </div>
            <div class="LM2-sun col-4">
                <img class="sun" src="/assets/common/images/ovals/{{LM2_Sun}}.png" alt="">
            </div>
            <div *ngIf="isMobileVersion" class="LM2-sun col-4">
                <img class="cloud" src="/assets/common/images/ovals/LM2_Cloud.png" alt="">
            </div>
        </div>
        <div class="LM2-files row">
            <div *ngIf="(_filesWithLevelE0$ | async)?.length>0" class="LM2-files-E0 col-4">
                <div class="E0-files">
                    <img class="zero" src="/assets/common/images/ovals/LM2_zero.png" alt="">
                    <img class="E0" src="/assets/common/images/ovals/LM3_Level0.png" alt="">
                    <ul>
                        <li *ngFor="let fileWithLevelE0 of _filesWithLevelE0$ | async">
                            <div class="fileName">
                                <a (click)="openFile(fileWithLevelE0?.file.id)" target="_blank">
                                    {{fileWithLevelE0.file.nameWithoutExtention}}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <img class="Girl" src="/assets/common/images/ovals/LM2_Girl.png" alt="">
            <img class="Giraffe" src="/assets/common/images/ovals/LM2_Giraffe.png" alt="">
            <div *ngIf="(_filesWithLevelE1$ | async)?.length>0" class="LM2-files-E1 col-4">
                <div class="E1-files">
                    <img class="one" src="/assets/common/images/ovals/LM2_one.png" alt="">
                    <img class="E1" src="/assets/common/images/ovals/LM3_Level1.png" alt="">
                    <ul>
                        <li *ngFor="let fileWithLevelE1 of _filesWithLevelE1$ | async">
                            <div class="fileName">
                                <a (click)="openFile(fileWithLevelE1?.file.id)" target="_blank">
                                    {{fileWithLevelE1.file.nameWithoutExtention}}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <img class="dog" src="/assets/common/images/ovals/LM2_Dog.png" alt="">
            <div *ngIf="(_filesWithLevelE2$ | async)?.length>0" class="LM2-files-E2 col-4">
                <div class="E2-files">
                    <img class="two" src="/assets/common/images/ovals/LM2_two.png" alt="">
                    <img class="E2" src="/assets/common/images/ovals/LM3_Level2.png" alt="">
                    <ul>
                        <li *ngFor="let fileWithLevelE2 of _filesWithLevelE2$ | async">
                            <div class="fileName">
                                <a (click)="openFile(fileWithLevelE2?.file.id)" target="_blank">
                                    {{fileWithLevelE2.file.nameWithoutExtention}}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <img class="turtle" src="/assets/common/images/ovals/LM2_Turtle.png" alt="">
        </div>
    </div>
</main>
<site-footer color="#b682eb"></site-footer>
