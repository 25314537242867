import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import './polyfills.ts';
import { RootModule } from './root.module';

const hookConsole = () => {
    const logs = [];
    window['_logs'] = logs;
    window['_logsDate'] = Date.now();
    hook('log');
    hook('info');
    hook('debug');
    hook('warn');
    hook('error');

    window.addEventListener("error", function (error) {
        addLog('error', {
            error: error.error,
            filename: error.filename,
            lineno: error.lineno,
            colno: error.colno,
            message: error.message,
        });
    });

    function addLog(type, args) {
        logs.push({
            arguments: args,
            type: type,
            date: Date.now()
        });

        window['_logsDate'] = Date.now();
    }

    function hook(methodName) {
        const _original = console[methodName];
        console[methodName] = function () {
            _original.apply(console, arguments);

            let _arguments = [];
            for (let i = 0; i < arguments.length; ++i) {
                _arguments.push(arguments[i])
            }

            addLog(methodName, arguments);
        }
    }
}

hookConsole();

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(RootModule);

/* "Hot Module Replacement" is enabled as described on
 * https://medium.com/@beeman/tutorial-enable-hrm-in-angular-cli-apps-1b0d13b80130#.sa87zkloh
 */

if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap); //HMR enabled bootstrap
    } else {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
} else {
    bootstrap(); //Regular bootstrap
}
