<siteHeader color="#FAEC3E" title="Tell Me More..." imgSrc="/assets/common/images/info/InfoHeader.png"></siteHeader>
    <div *ngIf="!isInAboutUsPage" id="info-images">
        <a  routerLink="/app/info/aboutUs"  routerLinkActive="active"  class="info-button aboutUs">
            About Us
        </a>

        <a  routerLink="/app/info/contact" routerLinkActive="active" class="info-button contact">
            Contact
        </a>

        <a  routerLink="/app/info/support" routerLinkActive="active" class="info-button support">
            Support
        </a>
    </div>

