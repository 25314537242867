  <main class="forEpisodes">
  <siteHeader title="Our Show!" imgSrc="/assets/common/images/header/{{ourShowHeader}}.png" backGroundColor="#F73061"
    color="#FAEC3E"></siteHeader>
    <div class="forAllEpisodes">
      <div *ngIf="!isMobileVersion" class="arrow">
        <img (click)="left()" src="/assets/common/images/ourshow/leftArrow.png" alt="leftArrow">
      </div>
      <div *ngFor="let episode of episodes" [routerLink]="['/app/episode', episode?.id]"
        class="content_image-container-forImage">
        <div class="mainForImage">
          <div class="forImageItself img-hover-zoom">
            <img *ngIf="episode.imageContent"
              [src]="remoteServiceBaseUrl + '/File/GetBinaryFile?id=' + episode?.imageContent" alt="showcase">
          </div>
        </div>
        <div class="title-Div">
          <h3 class="content_image-box-title">{{episode?.title}}</h3>
        </div>
        <div class="content_image-box-episode-name-div">
          <h3 class="content_image-box-episode-name">{{episode?.description}}</h3>
        </div>
      </div>
      <div *ngIf="!isMobileVersion" class="arrow">
        <img (click)="right()" src="/assets/common/images/ourshow/rightArrow.png" alt="rightArrow">
      </div>
      <div *ngIf="isMobileVersion && !isLearnMoreClicked" (click)="clickLearnMore()" class="learnMore-div">
        <h2 class="learnMore">
          Load more...
        </h2>
      </div>
    </div>
  </main>
<site-footer color="#1EA282"></site-footer>