import { NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { HomeComponent } from './shared/layout/site/home/home.component';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { AboutComponent } from './shared/layout/site/info/about-us/about-us.component';
import { ContactComponent } from './shared/layout/site/info/contact/contact.component';
import { SupportComponent } from './shared/layout/site/info/support/support.component';
import { PrivacyPolicyComponent } from './shared/layout/site/privacy-policy/privacy-policy.component';
import { OurShowComponent } from './shared/layout/site/our-show/our-show.component';
import { EpisodeComponent } from './shared/layout/site/episode/episode.component';
import { GamesComponent } from './shared/layout/site/games/games.component';
import { LearnMoreComponent } from './shared/layout/site/learn-more/learn-more.component';
import { OvalComponent } from './shared/layout/site/oval/oval';
import { OvalContentComponent } from './shared/layout/site/oval-content/oval-content.component';
import { AboutOurShowComponent } from './shared/layout/site/info/about-us-ourshow/about-us-ourshow.component';
import { AboutMediaComponent } from './shared/layout/site/info/about-us-media/about-us-media.component';
import { OvalContentDefaultComponent} from './shared/layout/site/oval-content/oval-content-Default/oval-content-Default.component';
import { OvalContentLM2Component } from './shared/layout/site/oval-content/oval-content-LM2/oval-content-LM2.component';
import { OvalContentLM3Component } from './shared/layout/site/oval-content/oval-content-LM3/oval-content-LM3.component';
import { OvalContentLM4Component } from './shared/layout/site/oval-content/oval-content-LM4/oval-content-LM4.component';
import { OvalContentLM5Component } from './shared/layout/site/oval-content/oval-content-LM5/oval-content-LM5.component';
import { OvalContentLM6Component } from './shared/layout/site/oval-content/oval-content-LM6/oval-content-LM6.component';
import { OvalContentLM1Component } from './shared/layout/site/oval-content/oval-content-LM1/oval-content-LM1.component';
import { DownloadPageComponent } from './shared/layout/site/downloadPage/downloadPage.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                // canActivate: [AppRouteGuard],
                // canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'home', component: HomeComponent },
                            { path: 'info/aboutUs', component: AboutComponent },
                            { path: 'info/aboutOurshow', component: AboutOurShowComponent },
                            { path: 'info/aboutMedia', component: AboutMediaComponent },
                            { path: 'info/contact', component: ContactComponent },
                            { path: 'info/support', component: SupportComponent },
                            { path: 'learn-more', component: LearnMoreComponent, canActivate: [AppRouteGuard], canActivateChild:[AppRouteGuard]},
                            { path: 'privacy-policy', component: PrivacyPolicyComponent },
                            { path: 'episodes', component: OurShowComponent, canActivate: [AppRouteGuard]},
                            { path: 'games', component: GamesComponent },
                            { path: 'url-download-page', component: DownloadPageComponent},
                            { path: 'episode/:id', component: EpisodeComponent, canActivate: [AppRouteGuard]},
                            { path: 'oval-content/:id', component: OvalContentComponent, canActivate: [AppRouteGuard]},
                            { path: 'oval-content-LM1/:id', component: OvalContentLM1Component, canActivate: [AppRouteGuard]},
                            { path: 'oval-content-LM2/:id', component: OvalContentLM2Component, canActivate: [AppRouteGuard]},
                            { path: 'oval-content-LM3/:id', component: OvalContentLM3Component, canActivate: [AppRouteGuard]},
                            { path: 'oval-content-LM4/:id', component: OvalContentLM4Component, canActivate: [AppRouteGuard]},
                            { path: 'oval-content-LM5/:id', component: OvalContentLM5Component, canActivate: [AppRouteGuard]},
                            { path: 'oval-content-LM6/:id', component: OvalContentLM6Component, canActivate: [AppRouteGuard]},
                            { path: 'oval-content-Default/:id', component: OvalContentDefaultComponent, canActivate: [AppRouteGuard]},
                            { path: 'notifications', component: NotificationsComponent },
                            { path: '', redirectTo: 'home', pathMatch: 'full' },
                        ],
                    },
                    {
                        path: 'main',
                        loadChildren: () => import('app/main/main.module').then((m) => m.MainModule), //Lazy load main module
                        data: { preload: true },
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: '**',
                        redirectTo: 'home',
                    },
                ],
            },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
            }
        });
    }
}
