import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxBootstrapLocaleMappingService } from 'assets/ngx-bootstrap/ngx-bootstrap-locale-mapping.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';

export class NgxBootstrapDatePickerConfigService {

    static getDaterangepickerConfig(): BsDaterangepickerConfig {
        return Object.assign(new BsDaterangepickerConfig(), {
            containerClass: 'theme-default'
        });
    }

    static getDatepickerConfig(): BsDatepickerConfig {
        return Object.assign(new BsDatepickerConfig(), {
            containerClass: 'theme-default'
        });
    }

    static getDatepickerLocale(): BsLocaleService {
        let localeService = new BsLocaleService();
        localeService.use(abp.localization.currentLanguage.name);
        return localeService;
    }

    private static _supportedLocales: string[] = [
        'ar',
        'bg',
        'ca',
        'cs',
        'da',
        'de',
        'en-gb',
        'es',
        'es-do',
        'es-pr',
        'es-us',
        'et',
        'fi',
        'fr',
        'gl',
        'he',
        'hi',
        'hr',
        'hu',
        'id',
        'it',
        'ja',
        'ka',
        'kk',
        'io',
        'lt',
        'lv',
        'mn',
        'nb',
        'nl',
        'nl-be',
        'pl',
        'pt-br',
        'ro',
        'ru',
        'sk',
        'sl',
        'sq',
        'sv',
        'th',
        'th-be',
        'tr',
        'uk',
        'vi',
        'zh-cn',
    ];

    static registerNgxBootstrapDatePickerLocales(): Promise<boolean> {
        if (abp.localization.currentLanguage.name === 'en') {
            return Promise.resolve(true);
        }

        let supportedLocale = new NgxBootstrapLocaleMappingService().map(abp.localization.currentLanguage.name).toLowerCase();
        let moduleLocaleName = new NgxBootstrapLocaleMappingService().getModuleName(abp.localization.currentLanguage.name);

        let isLocaleNotSupported = NgxBootstrapDatePickerConfigService._supportedLocales.indexOf(supportedLocale.toLowerCase()) == -1;
        if (isLocaleNotSupported) {
            console.warn('Not IMPLEMENTED hy lang for dateme picker');
            return Promise.resolve(true);
        }

        return new Promise<boolean>((resolve, reject) => {
            import(`/node_modules/ngx-bootstrap/chronos/esm2020/i18n/${supportedLocale}.mjs`)
                .then(module => {
                    defineLocale(abp.localization.currentLanguage.name.toLowerCase(), module[`${moduleLocaleName}Locale`]);
                    resolve(true);
                }, () => {
                    console.error(`Not FOUND ${supportedLocale} lang for dateme picker`);
                    return Promise.resolve(true);
                });
        });
    }
}
