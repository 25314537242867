import { Injectable } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { SessionServiceProxy } from '@shared/service-proxies/service-proxies';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class LanguageService {
    
    appSession: AppSessionService;
    
    constructor(
        private _sessionService: SessionServiceProxy,

    )  {
        this.tetantLanguageName$.subscribe(); 
    }

    readonly tetantLanguageName$ = this._sessionService.getCurrentLoginInformations().pipe(
        map((x)=>
        {
            if(x.tenant)
            {
                return x.tenant.languageName;
            }
            return "Georgian";
        }),
        shareReplay(1)
    );
}
