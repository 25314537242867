import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EpisodesServiceProxy } from '@shared/service-proxies/service-proxies';
import { map as _map, filter as _filter } from 'lodash-es';
import Swal from 'sweetalert2';
import { Language, LanguageData } from './shared/layout/site/language-picker-local/LanguageConsts';

@Injectable()
export class EpisodeGuardService {

    languages: Language[] = LanguageData.languages;
    nativeIndex: number = 0;
    popUped: boolean = false;

    constructor(
        private _router: Router,
        private _episodeService: EpisodesServiceProxy,
    ) {

    }

    nextEpisode(): void {
        var goingToEpisode = this._router.url.includes("/episode/");
        if (goingToEpisode) {
            var routing = this._router.url;
            var goingId = Number(routing?.substring(routing.length - 2, routing.length));
            if (isNaN(goingId)) {
                goingId = Number(routing?.substring(routing.length - 1, routing.length));
            }
            this._episodeService.getCurrentEpisodeStatus(goingId).subscribe((result) => {
                if (result.mustFinishEpisodeId !== 0 && result.mustFinishEpisodeId !== null
                    && result.mustFinishEpisodeId !== undefined && !this.popUped) {
                    this.episodePopUp(result.mustFinishEpisodeId);
                    this.popUped = true;
                }
            })
        }
    }

    episodePopUp(id: number): void {
        Swal.fire({
            title: `The episode is not available`,
            text: 'Please finish watching the previous episode and submit the form',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#50f8ac',
            focusCancel: true,
            confirmButtonText: 'OK',
        }).then((result) => {
            this._router.navigate(['/app/episode', id]);
            setTimeout(() => {
                this.popUped = false;
                location.reload();
            });

            if (result.isConfirmed) {
            } else {
                this._router.navigate(['/app/episode', id]);
                setTimeout(() => {
                    this.popUped = false;
                    location.reload();
                });
            }
        });
    }
}
