<home-header></home-header>
<main class="home-main">
  <section class="home-first_section">
    <section class="row d-flex">
      <div class="col-6 watch_show">
        <img routerLink="/app/episodes" class="watch_show_image" [src]="ourShowImage">
        <img routerLink="/app/episodes" class="watch_show_button" src="/assets/common/images/home/watch-show-button.png">
      </div>
      <div class="col-3 play_games">
        <img routerLink="/app/games" class="play_games_image" src="/assets/common/images/home/play_games_button.png">
      </div>
      <div class="col-3 learn_more">
        <img routerLink="/app/learn-more" class="learn_more_image"
          src="/assets/common/images/home/learn_more_button.png">
      </div>
    </section>
  </section>
  <section class="home-second_section">
    <section>
      <div class="ball-div col-3">
        <img class="ball" src="/assets/common/images/home/ball.png">
      </div>
      <div class="title-div col-6">
        <div class="d-flex">
          <h2 class=" col-8 home-section_2__title">{{ 'ALittleBitMoreAboutUs' | localize }}
          </h2>
        </div>
        <div class="buttons-div row d-flex">
          <div routerLink="/app/info/aboutUs">
            <img class="about_us_button_image" src="/assets/common/images/home/about_us.png">
          </div>
          <div routerLink="/app/info/contact">
            <img class="contact_button_image" src="/assets/common/images/home/contact.png">
          </div>
        </div>
      </div>
      <div class="harry-div col-3">
        <img class="harry_second" src="/assets/common/images/home/harry_section2-with-leg.png">
      </div>
    </section>
  </section>
</main>
<site-footer style="bottom: 0"></site-footer>