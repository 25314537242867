<div class="anim_desc-container-newdesign">
  <div class="anim_desc-container-newdesign-div">
    <div id="leftArrow" class="arrow" (click)="leftClick()">
      <img class="arrow-img" src="/assets/common/images/ourshow/leftArrow.png" alt="leftArrow">
    </div>
    <div *ngFor="let episode of episodes" [routerLink]="['/app/episode', episode.id]" class="carousel-episode" (click)="navigateByCarousel(episode.id)">
      <div class="carousel-episode-image-div">
        <img *ngIf="episode.imageContent" class="carousel-episode-image"
          [src]="remoteServiceBaseUrl + '/File/GetBinaryFile?id=' + episode?.imageContent" alt="showcase">
        </div>
        <div class="carousel-episode-title-div">
          
          <h3 class="carousel-episode-title">{{episode?.title}}</h3>
        </div>
      </div>
      <div id="rightArrow" class="arrow" (click)="rightClick()">
        <img class="arrow-img" src="/assets/common/images/episode/rightArrow2.png" alt="rightArrow">
      </div>
    </div>
  </div>