import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EpisodeDto, EpisodesServiceProxy } from '@shared/service-proxies/service-proxies';
import { BehaviorSubject, combineLatest, map, mergeMap, tap } from 'rxjs';
import { LanguageService } from '../language-picker-local/language-picker-local.service';
import { Language, LanguageData } from '../language-picker-local/LanguageConsts';

@Component({
    templateUrl: './our-show.component.html',
    styleUrls: ['./our-show.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'our-show',
})

export class OurShowComponent extends AppComponentBase {
    languages: Language[];
    episodes: EpisodeDto[] = [];
    episodesAll: EpisodeDto[] = [];

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    firstIndex: number = 0;
    secondIndex: number = 1;
    thirdIndex: number = 2;
    eposidesArrrayLength: number = 0;
    isLearnMoreClicked:boolean=false;
    screenWidth: number = window.innerWidth;
    isMobileVersion: boolean = false;
    mobileVersionWidth: number = 575.98;
    ourShowHeader:string="ourShow-header";
    
    constructor(
        injector: Injector,
        private _episodeService: EpisodesServiceProxy,
        private _languageService: LanguageService,
    ) {
        super(injector);
        this.languages = LanguageData.languages;
        this._episodes$.subscribe();
    }

    ngOnInit(): void {
        this._mobileVersionCheck();
    }

    private readonly tenantLanguageName$ = this._languageService.tetantLanguageName$;

    private readonly _allEpisodesSubject = new BehaviorSubject<EpisodeDto[]>(undefined);
    readonly _allEpisodes$ = this._allEpisodesSubject.asObservable().pipe(
        mergeMap(() => this._episodeService.getAllEpisodes()),
    )

    readonly _episodes$ = combineLatest([
        this._allEpisodes$,
        this.tenantLanguageName$,
    ]).pipe(
        map(([allEpisodes, tenantLanguageName]) => {
            return allEpisodes.filter((episodes) => episodes.applicationLanguageName == tenantLanguageName);
        }),
        tap((episodes) => {
            this.episodesAll = episodes;
            this.eposidesArrrayLength = episodes.length;
            this.placeEpisodes();
        })
    );

    placeEpisodes() {
        this.episodes[0] = this.episodesAll[this.firstIndex];
        this.episodes[1] = this.episodesAll[this.secondIndex];
        this.episodes[2] = this.episodesAll[this.thirdIndex];
    }

    clickLearnMore(){
        this.episodes=this.episodesAll;
        this.isLearnMoreClicked=true;
    }

    left() {
        this.regularDecrease();
        if (this.firstIndex < 0) {
            this.firstIndex = this.eposidesArrrayLength - 1;
        }
        if (this.secondIndex < 0) {
            this.secondIndex = this.eposidesArrrayLength - 1;
        }
        if (this.thirdIndex < 0) {
            this.thirdIndex = this.eposidesArrrayLength - 1;
        }
        this.placeEpisodes();
    }

    right() {
        this.regularIncrease();
        if (this.firstIndex === this.eposidesArrrayLength) {
            this.firstIndex = 0;
        }
        if (this.secondIndex === this.eposidesArrrayLength) {
            this.secondIndex = 0;
        }
        if (this.thirdIndex === this.eposidesArrrayLength) {
            this.thirdIndex = 0;
        }
        this.placeEpisodes();
    }

    regularDecrease() {
        this.firstIndex--;
        this.secondIndex--;
        this.thirdIndex--;
    }

    regularIncrease() {
        this.firstIndex++;
        this.secondIndex++;
        this.thirdIndex++;
    }

    private _mobileVersionCheck() {
        this.isMobileVersion = this.screenWidth <= this.mobileVersionWidth;
        if (this.isMobileVersion) {
            this.ourShowHeader="ourShow-headerMobile";
        }
    }
}

