<footer [class.forFooterMain-contact]="isInContactPage" [class.forFooterMain-support]="isInSupportPage"
  [class.forFooterMain-about]="isInAboutPage" class="forFooterMain">
  <div class="forFooter">
    <div id="forLinks" style="margin-top: 140px; display: flex; flex-direction: column;" class="footer-container">
      <div class="footer-links">
        <a class="footer-link" routerLink="/app/episodes">{{ 'Our Show!'}}</a>
        <a class="footer-link" routerLink="/app/games">{{ 'Games'}}</a>
        <a class="footer-link" routerLink="/app/learn-more">{{ 'Learn More'}}</a>
        <a class="footer-link" routerLink="/app/info/aboutUs">{{ 'About Us'}}</a>
        <a class="footer-link" routerLink="/app/info/contact">{{ 'Contact'}}</a>
      </div>
      <div id="forLine">
      </div>
    </div>
    <div class="footer-container">
      <div id="footerHarry" class=" footer-harry">
        <img class="footer-harry-image" src="/assets/common/images/home/harry.png" alt="logo">
      </div>
      <div class="logo-and-privacy">
        <div class="footer-logo">
          <img class="foot-logo" src="/assets/common/images/header/logo.png" alt="logo">
        </div>
        <div class="privacy-and-copyright">
          <a class="forA" routerLink="/app/privacy-policy" routerLinkActive="active">
            {{ 'Privacy Policy'}}
          </a>
          <span>Copyright-Wordplay {{currentYear}}</span>
        </div>
      </div>
    </div>
  </div>
</footer>