import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AboutUsService } from '../about-us.service';


@Component({
    templateUrl: './about-us-media.component.html',
    styleUrls: ['./about-us-media.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'about-us-media',
})
export class AboutMediaComponent extends AppComponentBase {

    constructor(
        injector: Injector,
        private _aboutUsService : AboutUsService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }
    
    AboutUsSlider(){
        this._aboutUsService.AboutUsSlider();
    }
}
