import { Component, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    Router
} from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { EpisodeDto, EpisodeOvalsServiceProxy, EpisodesServiceProxy } from '@shared/service-proxies/service-proxies';
import { Dropdown } from 'primeng/dropdown';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, shareReplay, take, map, mergeMap, tap } from 'rxjs/operators';
import { LanguageService } from '../language-picker-local/language-picker-local.service';

@Component({
    templateUrl: './learn-more.component.html',
    styleUrls: ['./learn-more.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'learn-more',
})
export class LearnMoreComponent extends AppComponentBase {


    episodes: EpisodeDto[] = [];
    selectedEpisode: EpisodeDto;
    isEpisodesDropdownOpened = false;

    constructor(
        injector: Injector,
        private _router: Router,
        private _episodeService: EpisodesServiceProxy,
        private _languageService: LanguageService,
        private _sessionService: AppSessionService,
        private _episodeOvalsServiceProxy: EpisodeOvalsServiceProxy,
    ) {
        super(injector);
        if (!this._sessionService.user) {
            this._router.navigate(['/account/easy-login']);
            return;
        }

        const currentEpisodeId = parseInt(localStorage.getItem("currentEpisode"));
        console.info(`Initializing with episode id=${currentEpisodeId}`);
        if (currentEpisodeId > 0) {
            this._episodeIdSubject.next(currentEpisodeId);
        }
        this._initialize$.pipe(
            take(1)
        ).subscribe();
    }

    private readonly _tenantLanguageName$ = this._languageService.tetantLanguageName$;
    private readonly _allEpisodesSubject = new BehaviorSubject<EpisodeDto[]>(undefined);
    private readonly _allEpisodes$ = this._allEpisodesSubject.asObservable().pipe(
        mergeMap(() => this._episodeService.getAllEpisodes()),
        shareReplay(1)
    );

    private readonly _episodeIdSubject = new BehaviorSubject<number>(undefined);
    private readonly _episodeId$ = this._episodeIdSubject.asObservable().pipe(
        distinctUntilChanged()
    );
    private get _episodeId(): number {
        return this._episodeIdSubject.value;
    }

    readonly episodeOvals$ = this._episodeId$.pipe(
        mergeMap(episodeId => this._episodeOvalsServiceProxy.getAllDistinctEpisodeOvals(episodeId)),
    );

    private readonly _initialize$ = combineLatest([
        this._allEpisodes$,
        this._tenantLanguageName$
    ]).pipe(
        map(([allEpisodes, tenantLanguageName]) => {
            return allEpisodes.filter((episodes) => episodes.applicationLanguageName == tenantLanguageName);
        }),
        tap((episodes) => {
            this.episodes = episodes;
            const firstEpisodeId = episodes[0]?.id;
            const selectedEpisodeId = this._episodeId || firstEpisodeId;
            const selectedEpisode = episodes.find(episode => episode.id == selectedEpisodeId);
            console.info(`Set initial selected episode id=${selectedEpisodeId}`);

            this.selectedEpisode = selectedEpisode;
            // due to bug prime ng dropdown initial valud is not selected
            // so reset it after episodes are bound to dropdown
            if (selectedEpisode?.id !== firstEpisodeId) {
                setTimeout(() => this.selectedEpisode = selectedEpisode, 250);
            }
        })
    );

    @ViewChild('pDropdown')
    episodeDropdown: Dropdown;

    onDropdownChange($event) {
        const selectedEpisode: EpisodeDto = $event.value;
        const selectedEpisodeId = selectedEpisode.id;
        console.info(`Selecting episode id=${selectedEpisodeId}`);
        localStorage.setItem("currentEpisode", `${selectedEpisodeId}`);
        this._episodeIdSubject.next(selectedEpisodeId);
        console.info(`Selected episode id=${selectedEpisodeId}`);
    }

    onDropdownClosed() {
        this.isEpisodesDropdownOpened = false;
    }

    onDropdownOpened() {
        this.isEpisodesDropdownOpened = true;
    }
}
