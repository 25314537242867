<main [style.background-color]="color" id="forMainLM4" class="main-container">
    <siteHeader title="Learn More" imgSrc="/assets/common/images/learnMore/MillieWithFlower.png"
        backGroundColor="#D03E97" color="#F8E031"></siteHeader>
    <div class="LM4-body">
        <div class="LM4-oval-episode row">
            <div *ngIf="_ovalName$ | async as ovalName" class="LM4-oval col-3">
                <oval color="#D4D4F9" borderColor="#332092" textColor="black" description="{{ovalName}}"></oval>
            </div>
            <div class="LM4-episode col-5">
                <h1>{{episodeName}}
                </h1>
            </div>
        </div>
        <div class="LM4-files row">
            <div class="LM4-Girl col-4">
                <img src="/assets/common/images/ovals/{{imageName}}.png" alt="">
            </div>
            <div class=" LM4-files-div col-4">
                <div *ngFor="let fileWithLevel of _filesWithLevel$ | async" class="LM4-file col-4">
                    <img src="/assets/common/images/ovals/LM4-Melody.png" alt="">
                    <a (click)="openFile(fileWithLevel?.file.id)" class="ovalFileName" target="_blank">
                        {{fileWithLevel?.file.nameWithoutExtention}}</a>
                </div>
            </div>
            <div class="LM4-Harry col-4">
                <img src="/assets/common/images/ovals/LM4_Harry.png" alt="">
            </div>
        </div>
    </div>
</main>
<site-footer color="#b682eb"></site-footer>