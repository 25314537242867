import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './moving-text.component.html',
    styleUrls: ['./moving-text.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'moving-text',

})
export class MovingTextComponent extends AppComponentBase {

    @Input() heading = "";
    dataText = [
        "Come and play with us",
        "Eja të lozësh me ne",
        "Zagraj z nami!",
        "Ven y juega con nosotros",
        "Venez et jouez avec nous",
        "მოდი და ითამაშეთ ჩვენთან ერთად",
        "来和我们一起玩",
        "是非、私たちと遊んでください",
        "Hãy đến và chơi với chúng tôi",
    ];
    textSpeed: number = 30;
    deletingSpeed: number = 30;
    text?= "";
    fullText?= "";
    loopNum = 0;
    typingSpeed = 150;
    i: number = 0;
    index: number = 0;
    movingFunction = null;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.handleType();
        }, 800);
    }

    handleType(): void {
        this.i = this.loopNum % this.dataText.length;
        this.fullText = this.dataText[this.i];
        this.movingFunction = setInterval(() => {
            this.text = this.fullText.substring(0, this.text.length + 1);
            if (this.text === this.fullText) {
                setTimeout(() => {
                    clearInterval(this.movingFunction);
                    this.movingFunction = setInterval(() => {
                        this.text = this.fullText.substring(0, this.text.length - 1);
                        if (this.text === "") {
                            clearInterval(this.movingFunction);
                            this.loopNum++;
                            this.handleType();
                        }
                    }, 50);
                }, 300);
            }
        }, 50);
    }
}



