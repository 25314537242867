<main [style.background-color]="color" id="forMainLM5" class="main-container">
    <siteHeader title="Learn More" imgSrc="/assets/common/images/learnMore/MillieWithFlower.png"
        backGroundColor="#D03E97" color="#F8E031"></siteHeader>

    <div class="LM5-body">
        <div class="LM5-oval-episode row">
            <div *ngIf="_ovalName$ | async as ovalName" class="LM5-oval col-3">
                <oval color="#F6F66B" borderColor="#F9B105" textColor="black" description="{{ovalName}}"></oval>
            </div>
            <div class="LM5-episode col-5">
                <h1>{{episodeName}}
                </h1>
            </div>
        </div>

        <div id="LM5_images_divId" class="LM5-images-div row">
            <div class="LM5-image5 col-3">
                <img src="/assets/common/images/ovals/LM5_Image5.png" alt="">
            </div>
            <div class="LM5-image6 col-3">
                <img src="/assets/common/images/ovals/LM5_Image6.png" alt="">
            </div>
            <div class="LM5-image7 col-3">
                <img src="/assets/common/images/ovals/LM5_Image7.png" alt="">
            </div>
            <div class="LM5-image8 col-3">
                <img src="/assets/common/images/ovals/LM5_Image8.png" alt="">
            </div>
        </div>

        <div id="LM5_images_div" class="LM5-images-div row">
            <div class="LM5-image9 col-4">
                <img src="/assets/common/images/ovals/LM5_Image11.png" alt="">
            </div>
            <div class="LM5-image10 col-4">
                <div *ngFor="let fileWithLevel of _filesWithLevel$ | async" class="LM5-file col-4">
                    <img src="/assets/common/images/ovals/LM5-picto.png" alt="">
                    <a (click)="openFile(fileWithLevel?.file.id)" class="ovalFileName" target="_blank">
                        {{fileWithLevel?.file.nameWithoutExtention}}</a>
                </div>
            </div>
            <div class="LM5-image11 col-4">
                <img src="/assets/common/images/ovals/LM5_Image9.png" alt="">
            </div>
        </div>
        <img id="LM5_Image10" src="/assets/common/images/ovals/LM5_Image10.png" alt="">
    </div>
</main>
<site-footer color="#b682eb"></site-footer>