import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'site-footer',
})
export class SiteFooterComponent extends AppComponentBase {

    @Input() color: string="#6C8DF9";
    currentYear: number;
    isInAboutPage:boolean=false;
    isInContactPage:boolean=false;
    isInSupportPage:boolean=false;

    constructor(
        injector: Injector,
        private router: Router
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        this.currentPageCheck();
        this.getCurrentYear();
    }
    
    currentPageCheck(){
        if (this.router.url.includes("about")) {
            this.isInAboutPage=true;
        }
        if (this.router.url.includes("contact")) {
            this.isInContactPage=true;
        }
        if (this.router.url.includes("support")) {
            this.isInSupportPage=true;
        }
    }
    
    getCurrentYear(){
        this.currentYear=(new Date()).getFullYear();
    }
}
