import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';


@Component({
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'contact',
})
export class ContactComponent extends AppComponentBase {

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }
}
