import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EntityDto, EpisodeDto, EpisodesServiceProxy, EpisodeUsersServiceProxy } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { BehaviorSubject, combineLatest, merge} from 'rxjs';
import { distinctUntilKeyChanged, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { EpisodeMedia } from './episode-media';
import { VgApiService, VgMediaDirective } from '@videogular/ngx-videogular/core';

@Component({
    templateUrl: './episode-panel.component.html',
    styleUrls: ['./episode-panel.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'episode-panel',
})
export class EpisodePanelComponent extends AppComponentBase {

    scrolled: boolean = false;
    episodeId: number = Number(this.route.snapshot.paramMap.get('id'));
    entityDto: EntityDto = new EntityDto();
    isVideoMuted: Boolean = true;
    isAudioMuted: Boolean = true;
    played: boolean = false;

    private _mediaApi: VgApiService;
    private _media: EpisodeMedia;

    constructor(
        injector: Injector,
        private route: ActivatedRoute,
        private _episodeService: EpisodesServiceProxy,
        private _episodeUsersServiceProxy: EpisodeUsersServiceProxy,
    ) {

        super(injector);
        this._streams.subscribe();
        this.entityDto.id = Number(this.route.snapshot.paramMap.get('id'));
    }

    onPlayerReady($event: VgApiService) {
        this._mediaApi = $event;
        this.configureFullScreen();
        this.initializeVideo();
        this.initializeAudio();
    }

    private configureFullScreen() {
        const polyfill = this._mediaApi.fsAPI.polyfill;
        console.log(`WP: full screen request is '${polyfill.request}'`);
    }

    private get videoMedia(): VgMediaDirective {
        return this._mediaApi.medias['videoElement'];
    }

    private get videoElement(): HTMLVideoElement {
        return this.videoMedia?.elem;
    }

    private get audioMedia(): VgMediaDirective {
        return this._mediaApi.medias['audioElement'];
    }

    private get audioElement(): HTMLAudioElement {
        return this.audioMedia?.elem;
    }

    private initializeVideo(): void {
        try {
            this.subscribeMedia('videoElement');
            console.log('WP: setting video url ' + this._media.videoUrl);
            this.videoElement.src = this._media.videoUrl;
        } catch (e) {
            console.error(e);
        }
    }

    private initializeAudio(): void {
        try {
            this.subscribeMedia('audioElement');
            console.log('WP: setting audio url ' + this._media.audioUrl);
            this.audioElement.src = this._media.audioUrl;
        } catch (e) {
            console.error(e);
        }
    }

    private subscribeMedia(mediaId: string): void {
        const media = this._mediaApi.getMediaById(mediaId);
        const subscriptions = media.subscriptions;

        subscriptions.error.subscribe((e) => {
            console.log(`WP: ${mediaId} error:`, e.target.error);
        });

        subscriptions.abort.subscribe((e) => {
            console.log(`WP: ${mediaId} aborted`);
        });

        subscriptions.emptied.subscribe((e) => {
            console.log(`WP: ${mediaId} emptied`);
        });

        subscriptions.stalled.subscribe((e) => {
            console.log(`WP: ${mediaId} stalled`);
        });

        subscriptions.suspend.subscribe((e) => {
            console.log(`WP: ${mediaId} suspend`);
        });

        subscriptions.waiting.subscribe((e) => {
            console.log(`WP: ${mediaId} waiting`);
        });

        subscriptions.loadedData.subscribe((e) => {
            console.log(`WP: ${mediaId} loadedData`);
        });

        subscriptions.loadedMetadata.subscribe((e) => {
            console.log(`WP: ${mediaId} loadedMetadata`);
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.stopSyncMedia();
    }

    private _syncMediaIntervalId;
    private startSyncMedia(): void {
        this.stopSyncMedia();
        this._syncMediaIntervalId = setInterval(() => {
            this.syncMedia();
        }, 500);
    }

    private stopSyncMedia(): void {
        if (this._syncMediaIntervalId) {
            clearInterval(this._syncMediaIntervalId);
            this._syncMediaIntervalId = null;
        }
    }

    isMediaLoading: boolean;
    isMediaPlaying: boolean;

    private _isSyncingMedia = false;
    private syncMedia(): Promise<void> {
        if (this._isSyncingMedia) {
            return;
        }

        this._isSyncingMedia = true;
        try {
            this._syncMedia();
        } catch (e) {
            console.error('WP: media sync error', e);
        } finally {
            this._isSyncingMedia = false;
        }
    }

    private _syncMedia(): Promise<void> {
        if (!this.isMediaPlaying) {
            return;
        }

        const audioMedia = this.audioMedia;
        const videoMedia = this.videoMedia;
        const audioElement = this.audioElement;
        const videoElement = this.videoElement;

        const videoState = videoElement.readyState;
        if (videoState < HTMLMediaElement.HAVE_FUTURE_DATA) {
            this.isMediaLoading = true;

            console.log(`WP: Video has not enough data to play, state=${videoState}. Pausing audio.`
                + ` video current time=${videoMedia.currentTime}`
                + ` audio current time=${audioMedia.currentTime}`
                );
            audioMedia.currentTime = videoMedia.currentTime;
            audioMedia.pause();
            return;
        }

        const audioState = audioElement.readyState;
        if (audioState < HTMLMediaElement.HAVE_FUTURE_DATA) {
            this.isMediaLoading = true;

            console.log(`WP: Audio has not enough data to play, state=${audioState}. Pausing video.`
                + ` video current time=${videoMedia.currentTime}`
                + ` audio current time=${audioMedia.currentTime}`
            );

            videoMedia.currentTime = audioMedia.currentTime;
            videoMedia.pause();
            return;
        }

        const audioCurrentTime = audioMedia.currentTime;
        const videoCurrentTime = videoMedia.currentTime;
        const mediaTimeDifference = Math.abs(audioCurrentTime - videoCurrentTime);
        const isCurrentTimeUnsyncronized: boolean = mediaTimeDifference > 0.3;
        if (isCurrentTimeUnsyncronized) {
            console.log('WP: Video and audio time unsyncronized. Syncing: '
                + ` video current time=${videoMedia.currentTime}`
                + ` audio current time=${audioMedia.currentTime}`
            );
            audioMedia.currentTime = videoMedia.currentTime;
        }

        if (videoElement.paused) {
            console.log(`WP: Video was paused, state ${videoMedia.state}, play start`);
            videoMedia.play();
            console.log('WP: Video was paused, play started');
        }

        if (audioElement.paused) {
            console.log(`WP: Audio was paused, state ${audioMedia.state}, play start`);
            audioMedia.play();
            console.log('WP: Audio was paused, play started');
        }

        if (this.isMediaLoading) {
            console.log('WP: Syncing media: playing');
            this.isMediaLoading = false;
        }
    }

    playPauseMedia() {
        if (this.isMediaPlaying) {
            this.pauseMedia();
        } else {
            this.playMedia();
        }
    }

    playMedia() {
        if (this.isAudioMuted) {
            console.log('WP: unmuting audio');
            this.isAudioMuted = false;
        }

        console.log('WP: play media');
        this.isMediaPlaying = true;
    }

    pauseMedia() {
        try {
            this.videoMedia.pause();
            this.audioMedia.pause();

            this.isMediaPlaying = false;
            this.isMediaLoading = false;
        } catch (e) {
            console.error('WP: pause error', e);
        }
    }

    ngOnInit(): void {

    }

    private readonly _allEpisodesSubject = new BehaviorSubject<EpisodeDto[]>(undefined);
    readonly _allEpisodes$ = this._allEpisodesSubject.asObservable().pipe(
        mergeMap(() => this._episodeService.getAllEpisodes()),
    );

    readonly _currentEpisode$ = this._allEpisodes$.pipe(
        map((episodes) => {
            var episode = episodes.find(x => x.id === this.episodeId);
            return episode;
        }),
        distinctUntilKeyChanged('id')
    )

    readonly _episodeTitle$ = this._currentEpisode$.pipe(
        map((episode) => episode.title),
    )

    readonly _episodeVideoUrl$ = this._currentEpisode$.pipe(
        map((episode) => {
            if (episode?.episodeVideoContent) {
                return this.getUrl(episode?.episodeVideoContent)
            }
            return '';
        }),
    );

    readonly _syncAudioVideo$ = this._episodeVideoUrl$.pipe(
        tap(() => {
            this.startSyncMedia();
        }),
    );

    readonly _episodeAudioUrl$ =  this._currentEpisode$.pipe(
    map((episode) => {
            if (episode?.audioContent) {
            return this.getUrl(episode.audioContent);
            }
            
            return 'url';
        })
        );

    readonly episodeAudio$ = combineLatest([
        this._episodeVideoUrl$,
        this._episodeAudioUrl$
    ]).pipe(
        map(([videoUrl, audioUrl]) => {
            return new EpisodeMedia(videoUrl, audioUrl);
        }),
        shareReplay(1)
    );

    readonly episodeMedia$ = combineLatest([
        this._episodeVideoUrl$,
        this._episodeAudioUrl$
    ]).pipe(
        map(([videoUrl, audioUrl]) => {
            return new EpisodeMedia(videoUrl, audioUrl);
        }),
        tap(media => this._media = media),
        shareReplay(1)
    );

    readonly _episodeScriptUrl$ = this._currentEpisode$.pipe(
        map((episode) => {
            if (episode?.scriptContent) {
                return this.getUrl(episode?.scriptContent)
            }
            return '';
        }),
    );

    readonly _episodeVocabularyUrl$ = this._currentEpisode$.pipe(
        map((episode) => {
            if (episode?.vocabularyContent) {
                return this.getUrl(episode?.vocabularyContent)
            }
            return '';
        }),
    );

    private readonly _urlSubject = new BehaviorSubject<string>(undefined);
    readonly _url$ = this._urlSubject.asObservable().pipe(
        map((fileGuid) => `${AppConsts.remoteServiceBaseUrl}/File/GetBinaryFile?id=${fileGuid}`),
        tap((x) => {
            window.open(x, '_blank');
        })
    );

    private readonly _streams = merge(
        this._episodeVideoUrl$,
        this._syncAudioVideo$
    )

    watchedEpisode() {
        this._episodeUsersServiceProxy.userWatchedVideo(this.entityDto).subscribe();
    }

    mediaEnded() {
        this.goToBeginning();
        this.isMediaPlaying = false;
        this.pauseMedia();
        this.watchedEpisode()
    }

    goToBeginning() {
        this.audioMedia.currentTime = 0;
        this.videoMedia.currentTime = 0;
    }

    onVideoEnd() {
        this.watchedEpisode()
    }

    getUrl(id: string): string {
        return AppConsts.remoteServiceBaseUrl + '/File/DownloadBinaryFile?id=' + id;
    }

    scrollToForm(): void {
        if (!this.scrolled && document.getElementById("forVideoPlayer")) {
            document.getElementById("forVideoPlayer")?.scrollIntoView({ behavior: "smooth" });
            this.scrolled = true;
        }
    }

    openFile(fileGuid: string): void {
        this._urlSubject.next(fileGuid);
        this._url$.pipe(
            take(1),
        ).subscribe();
    }
}
