import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EpisodeDto, EpisodesServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppConsts } from '@shared/AppConsts';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../language-picker-local/language-picker-local.service';
import { Language, LanguageData } from '../../language-picker-local/LanguageConsts';
import { concatMap, map, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, merge, Subject } from 'rxjs';

@Component({
    templateUrl: './episode-description.component.html',
    styleUrls: ['./episode-description.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'episode-description',
})
export class EpisodeDescriptionComponent extends AppComponentBase {

    languages: Language[];
    episodesLength: number;
    episodeId: number = Number(this.route.snapshot.paramMap.get('id'));
    episodes: EpisodeDto[] = [];
    episodesAll: EpisodeDto[] = [];

    firstIndex: number = 0;
    secondIndex: number = 1;
    thirdIndex: number = 2;
    eposidesArrrayLength: number = 0;

    screenWidth: number = window.innerWidth;
    isMobileVersion: boolean = false;
    mobileVersionWidth: number = 575.98;

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(
        private _episodeService: EpisodesServiceProxy,
        injector: Injector,
        private _router: Router,
        private route: ActivatedRoute,
        private _languageService: LanguageService,
    ) {
        super(injector);
        this.languages = LanguageData.languages;
        this._streams.subscribe();
    }

    private readonly tenantLanguageName$ = this._languageService.tetantLanguageName$;

    readonly _allEpisodes$ = this._episodeService.getAllEpisodes();

    readonly _episodes$ = combineLatest([
        this._allEpisodes$,
        this.tenantLanguageName$
    ]).pipe(
        map(([episodes, name]) => {
            var episodesInCurrentLanguage = episodes.filter((x) => x.applicationLanguageName == name);

            this.episodesLength = episodesInCurrentLanguage.length;
            return episodesInCurrentLanguage;
        }),
        shareReplay(1),
        tap((episodes) => {
            this.episodesAll = episodes;
            this.eposidesArrrayLength = episodes.length;
            if (this.isMobileVersion) {
                this.placeEpisodesMobile();
                return;
            }
            this.placeEpisodes();
        })
    );

    private readonly __currentEpisodeIndexSubject = new BehaviorSubject<number>(undefined);
    readonly _currentEpisodeIndex$ = this._episodes$.pipe(
        map((episodes) => {
            return episodes.findIndex(x => x.id === this.episodeId);
        }),
        tap((currentIndex) => {
            if(this.isMobileVersion)
            {
                this.nextEpisodesMobile(currentIndex);
                return;
            }
            this.nextEpisodes(currentIndex);
        })
    )

    get _currentEpisodeIndex() {
        return this.__currentEpisodeIndexSubject.value;
    }

    readonly _nextEpisodeIndex$ = combineLatest([
        this._episodes$,
        this._currentEpisodeIndex$
    ]).pipe(
        map(([episodes, currentIndex]) => {
            var nextIndex = currentIndex + 1;
            if (nextIndex >= episodes?.length) {
                this._nextEpisodeIndexValueSubject.next(0);
                return 0
            }
            else {
                this._nextEpisodeIndexValueSubject.next(nextIndex);
                return nextIndex;
            }
        }),
    );

    private readonly _nextEpisodeIndexValueSubject = new BehaviorSubject<number>(0);
    readonly _nextEpisodeIndexValue$ = this._nextEpisodeIndexValueSubject.asObservable().pipe()

    readonly _nextEpisode$ = combineLatest([
        this._episodes$,
        this._nextEpisodeIndexValue$
    ]).pipe(
        map(([episodes, nextIndex]) => {
            var nextEpisode = episodes[nextIndex];
            return nextEpisode;
        })
    )

    readonly _nextEpisodeimageSrc$ = this._nextEpisode$.pipe(
        map((episode) =>
            `${AppConsts.remoteServiceBaseUrl}/File/GetBinaryFile?id=${episode?.imageContent}`
        )
    )

    private readonly _navigateNextEpisodeSubject = new Subject<void>();
    private readonly _navigateNextEpisode$ = this._navigateNextEpisodeSubject.asObservable().pipe(
        concatMap(() => this._nextEpisode$),
        tap((nextEpisode) => {
            this.navigate(nextEpisode.id)
        }),
    );

    private readonly _streams = merge(
        this.tenantLanguageName$,
        this._episodes$,
        this._currentEpisodeIndex$,
        this._navigateNextEpisode$,
        this._nextEpisodeIndex$
    )

    private navigate(id: number): void {
        this._router.navigate(['./app/episode', id]).then(() => {
            window.location.reload();
        });
    }

    ngOnInit(): void {
        this.mobileVersionCheck();
    }

    navigateByCarousel(id: number): void {
        this._router.navigate(['./app/episode', id]).then(() => {
            window.location.reload();
        });
    }

    nextEpisode(): void {
        this._navigateNextEpisodeSubject.next();
    }

    public placeEpisodes() {
        this.episodes[0] = this.episodesAll[this.firstIndex];
        this.episodes[1] = this.episodesAll[this.secondIndex];
        this.episodes[2] = this.episodesAll[this.thirdIndex];
    }

    left() {
        this.regularDecrease();
        if (this.firstIndex < 0) {
            this.firstIndex = this.eposidesArrrayLength - 1;
        }
        if (this.secondIndex < 0) {
            this.secondIndex = this.eposidesArrrayLength - 1;
        }
        if (this.thirdIndex < 0) {
            this.thirdIndex = this.eposidesArrrayLength - 1;
        }
        this.placeEpisodes();
    }

    right() {
        this.regularIncrease();
        if (this.firstIndex === this.eposidesArrrayLength) {
            this.firstIndex = 0;
        }
        if (this.secondIndex === this.eposidesArrrayLength) {
            this.secondIndex = 0;
        }
        if (this.thirdIndex === this.eposidesArrrayLength) {
            this.thirdIndex = 0;
        }
        this.placeEpisodes();
    }

    private nextEpisodes(currentIndex: number){
        this.firstIndex = currentIndex + 1;
        this.secondIndex = currentIndex + 2;
        this.thirdIndex = currentIndex + 3;
        if (currentIndex == this.eposidesArrrayLength-1) {
            this.firstIndex = 0;
            this.secondIndex = 1;
            this.thirdIndex = 2;
        }
        if (currentIndex == this.eposidesArrrayLength-2) {
            this.firstIndex = currentIndex+1;
            this.secondIndex = 0;
            this.thirdIndex = 1;
        }
        if (currentIndex == this.eposidesArrrayLength-3) {
            this.firstIndex = currentIndex+1;
            this.secondIndex = currentIndex+2;
            this.thirdIndex = 0;
        }
    }

    private regularDecrease() {
        this.firstIndex--;
        this.secondIndex--;
        this.thirdIndex--;
    }

    private regularIncrease() {
        this.firstIndex++;
        this.secondIndex++;
        this.thirdIndex++;
    }
    
    public placeEpisodesMobile() {
        this.episodes[0] = this.episodesAll[this.firstIndex];
        this.episodes[1] = this.episodesAll[this.secondIndex];
    }

    leftMobile() {
        this.regularDecreaseMobile();
        if (this.firstIndex < 0) {
            this.firstIndex = this.eposidesArrrayLength - 1;
        }
        if (this.secondIndex < 0) {
            this.secondIndex = this.eposidesArrrayLength - 1;
        }
        this.placeEpisodesMobile();
    }

    rightMobile() {
        this.regularIncreaseMobile();
        if (this.firstIndex === this.eposidesArrrayLength) {
            this.firstIndex = 0;
        }
        if (this.secondIndex === this.eposidesArrrayLength) {
            this.secondIndex = 0;
        }
        this.placeEpisodesMobile();
    }

    private nextEpisodesMobile(currentIndex: number){
        this.firstIndex = currentIndex + 1;
        this.secondIndex = currentIndex + 2;
        if (currentIndex == this.eposidesArrrayLength-1) {
            this.firstIndex = 0;
            this.secondIndex = 1;
        }
        if (currentIndex == this.eposidesArrrayLength-2) {
            this.firstIndex = currentIndex+1;
            this.secondIndex = 0;
        }
    }

    private regularDecreaseMobile() {
        this.firstIndex--;
        this.secondIndex--;
    }

    private regularIncreaseMobile() {
        this.firstIndex++;
        this.secondIndex++;
    }

    mobileVersionCheck() {
        this.isMobileVersion = this.screenWidth <= this.mobileVersionWidth;
    }

    leftClick(){
        if (this.isMobileVersion) {
            this.leftMobile();
            return;
        }
        this.left();
    }

    rightClick(){
        if (this.isMobileVersion) {
            this.rightMobile();
            return;
        }
        this.right();
    }
}

