<site-nav></site-nav>
<router-outlet></router-outlet>
<div *ngIf="showLogs$ | async">
    <div id="console-logs" class="console-logs">
        <button type="button" class="btn btn-light-primary btn-sm pull-right" (click)="copyLogs()">Copy</button>
        <div *ngFor="let item of logs" class="console-log console-log-{{item.type}}">
            <span class="console-log-date">[{{item.date | date:'HH:mm:ss'}}]:</span>
            <span class="console-log-argument" *ngFor="let argument of item.arguments">
                {{ argument | json }}
            </span>
        </div>
    </div>
</div>
