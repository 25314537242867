<info></info>
<div class="info-box about_us">
    <div class="info-box-inner">
        <div class="row ourShow-arrow">
            <img src="/assets/common/images/info/OurShow-arrow.png">
        </div>
        <div class="platform-media row">
            <ul>
                <li>
                    <span routerLink="/app/info/aboutOurshow" class="bullet">About the "Our Show!" platform</span>
                </li>
                <li>
                    <span routerLink="/app/info/aboutMedia" class="bullet">About WordPlay Media</span>
                </li>
            </ul>
        </div>
        <div class="row wordPlay-arrow-group">
            <img class="wordPlay-arrow" src="/assets/common/images/info/{{wordplay_arrow}}.png">
            <img class="wordPlay-group" src="/assets/common/images/info/Group.png">
        </div>
    </div>
</div>
<site-footer></site-footer>