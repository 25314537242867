<info></info>

<div class="about_us_ourshow">
    <div id="leftArrow" class="arrow">
        <img (click)="AboutUsSlider()" class="arrow-img" src="/assets/common/images/info/LeftArrow.png" alt="leftArrow">
    </div>
    <div class="platform-media">
        <h4 class="showAndMedia">
            The “Our Show!” platform
        </h4>
        <p>
            English is the world's language. Having a good grasp of English can dramatically improve a child's life
            chances,
            offering them a way to connect with the wider world, and opening a path to academic or career success.
        </p>
        <p>The problem:</p>
        <p>
            The best time to learn a language is during childhood. But too many kids don’t get the chance. They don’t
            have
            access to skilled, qualified, teaching – because there just aren’t enough teachers to meet the need. Many
            schools are under-resourced, and a lot of the teaching materials are simply dull.
        </p>
        <p>The solution:</p>
        <p>
            WordPlay’s “Our Show!” online learning platform. When kids watch the “Our Show!” animation, they watch it
            because it’s fun - but it’s also packed full of education. A short presentation before each episode
            introduces
            just a small amount of vocabulary and grammar. And when the characters speak, a speech bubble appears above
            their head – so kids can
            <strong class="Itallic">read</strong>
            the words, they can

            <strong class="Itallic">hear</strong>

            the words, and they can
            <strong class="Itallic">see</strong>
            the action.
        </p>
        <p>
            When kids read, hear, and then see someone
            <strong>‘pull’</strong>
            , the learning process is strongly reinforced.
        </p>
        <p>
            And there’s also a translation available
            <u>
                <span class="underLine">into any mother tongue</span>
            </u>
            , which allows kids to learn English while relying on the language they already speak.
        </p>
        <p>
            Finally, it doesn’t matter if a child doesn’t understand every word in an episode. They’ll understand and
            enjoy
            the story, and while some kids will pick up just a few new words, others might begin to get a feeling of how
            English grammar works.
        </p>
        <p>
            This is the process by which kids naturally learn a language. They hear a language, and they being to impute
            a
            lexical and grammatical framework.
        </p>
        <p>“Our Show!” makes learning fun, and lets kids learn at a pace that works for them, in their mother tongue.
        </p>

        <div routerLink="/app/info/aboutUs" class="close-button-div">
            <img src="/assets/common/images/info/AboutUsClose.png" alt="closeButton">
        </div>
    </div>
    <div class="arrow">
        <img (click)="AboutUsSlider()" class="arrow-img" src="/assets/common/images/info/RightArrow.png" alt="leftArrow">
    </div>
</div>

<site-footer></site-footer>