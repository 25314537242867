import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EpisodeDto } from '@shared/service-proxies/service-proxies';
import { Language, LanguageData } from '../language-picker-local/LanguageConsts';

@Component({
    templateUrl: './oval.html',
    styleUrls: ['./oval.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'oval',
})
export class OvalComponent extends AppComponentBase{

    episodes: EpisodeDto[] = [];
    urlVocabulary: string = '';
    nativeIndex: number = 0;
    model: EpisodeDto = new EpisodeDto();
    imageSource: string;
    languages: Language[]=LanguageData.languages;
    currentLanguageId: number = 0;
    data: any;
    label:string;
    name: string;

    @Input() borderColor: string="";
    @Input() color: string="";
    @Input() textColor: string="";
    @Input() description: string="";

    constructor(
        injector: Injector
    ) {
        super(injector);
        this.languages = LanguageData.languages;
    }
    
    ngOnInit(): void {
        this.label=this.description.substring(0, 3)
        this.name=this.description.slice(5, this.description.length)
    };
}


