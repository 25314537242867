import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'about-us',
})
export class AboutComponent extends AppComponentBase {
    wordplay_arrow:string="Wordplay-arrow";
    screenWidth: number = window.innerWidth;
    isMobileVersion: boolean = false;
    mobileVersionWidth: number = 575.98;

    constructor(
        injector: Injector,

    ) {
        super(injector);        
    }

    ngOnInit(): void {
        this.mobileVersionCheck();
    }

    scrollToForm(section: string): void {
        document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }

    mobileVersionCheck() {
        this.isMobileVersion = this.screenWidth <= this.mobileVersionWidth;
        if (this.isMobileVersion) {
            this.wordplay_arrow="Wordplay-arrow_Mobile";
        }
    }
}
