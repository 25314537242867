import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EpisodesServiceProxy, EpisodeUsersServiceProxy, TenantLoginInfoDto, TenantServiceProxy, UserListDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, mergeMap, map, tap, repeat, combineLatest, merge, shareReplay, switchMap } from 'rxjs';
import { EpisodeGuardService } from '@app/episode-guard.service';

@Component({
    templateUrl: './episode.component.html',
    styleUrls: ['./episode.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'episode',
})
export class EpisodeComponent extends AppComponentBase {

    watchedEpisode: boolean = false;
    submittedForm: boolean = false;
    feedbackRequired: boolean = false;
    refreshed: boolean = false;
    scrolled: boolean = false;

    constructor(
        injector: Injector,
        private _episodeService: EpisodesServiceProxy,
        private route: ActivatedRoute,
        public sanitizer: DomSanitizer,
        private _userServiceProxy: UserServiceProxy,
        private _episodeUserService: EpisodeUsersServiceProxy,
        private _tenantService: TenantServiceProxy,
        private _episodeGuardService: EpisodeGuardService,
    ) {
        super(injector);

        this._streams.subscribe();
    }

    private readonly _episodeIdSubject = new BehaviorSubject<number>(undefined);
    readonly _episodeId$ = this._episodeIdSubject.asObservable().pipe(
        map(() => Number(this.route.snapshot.paramMap.get('id'))),
        tap((currentEpisodeId) => localStorage.setItem("currentEpisode", currentEpisodeId.toString())),
        shareReplay(1),
    )

    get episodeId() {
        return this._episodeIdSubject.value;
    }

    readonly _episode$ = this._episodeId$.pipe(
        mergeMap((episodeId) => this._episodeService.getEpisodeForView(episodeId)),
        map((episodeDto) => episodeDto.episode),
    )

    readonly _currentEpisodeUser$ = this._episodeId$.pipe(
        switchMap((episodeId) => {
            return this._episodeUserService.getCurrentEpisodeUser(episodeId).pipe(
                repeat({ delay: 1000 }),
            )
        }),
    )

    private readonly _userSubject = new BehaviorSubject<UserListDto>(undefined);
    readonly _user$ = this._userSubject.asObservable().pipe(
        mergeMap(() => this._userServiceProxy.getCurrentUser()),
    )

    readonly _checkWatchedEpisode$ = this._currentEpisodeUser$.pipe(
        tap((currentEpisodeUser) => {
            this.watchedEpisode = currentEpisodeUser.hasWatched;
            this.submittedForm = currentEpisodeUser.hasFeedback;
            this.scrollToForm();
            if (currentEpisodeUser.hasFeedback &&
                localStorage.getItem(`${this.episodeId}`) !== "true") {
                localStorage.setItem(`${this.episodeId}`, "true");
            }
        }),
    )

    scrollToForm(): void {
        if (!this.scrolled && document.getElementById("iframe")) {
            document.getElementById("iframe")?.scrollIntoView({ behavior: "smooth" });
            this.scrolled = true;
        }
    }

    private readonly _tenantSubject = new BehaviorSubject<TenantLoginInfoDto>(undefined);
    readonly _tenant$ = this._tenantSubject.asObservable().pipe(
        map(() => this.appSession.tenant),
    )

    readonly _currentUserAge$ = this._user$.pipe(
        map((user) => (new Date().getUTCFullYear() - user.birthDate.year).toString()),
    )

    readonly _currentUserGender$ = this._user$.pipe(
        map((user) => user.gender),
        map((gender) => {
            switch (gender) {
                case 2:
                    return "boy";
                case 3:
                    return "girl";
                default:
                    return "boy";
            }
        }),
    );

    readonly _forUrl$ = combineLatest([
        this._episode$,
        this._tenant$,
        this._user$,
        this._currentUserGender$,
        this._currentUserAge$
    ]).pipe(
        map(([episode, tenant, user, gender, age]) => {
            var url = `${episode.googleFormUrl}`
                .replace("boy", gender)
                .replace("name", user.name)
                .replace("surname", user.surname)
                .replace("=10", `=${age}`)
                .replace("=1&", `=${abp.session.userId}&`)
                .replace("=123", `=${episode.id}`)
                .replace("SchoolName", `${tenant.name}`);

            return this.sanitizer.bypassSecurityTrustResourceUrl(url)
        }),
    );

    reload(): void {
        location.reload();
    }

    private readonly _feedBackRequiredForTenantSubject = new BehaviorSubject<Boolean>(undefined);
    readonly _feedBackRequiredForTenant$ = this._feedBackRequiredForTenantSubject.asObservable().pipe(
        mergeMap(() => this._tenantService.isFeedBackRequiredForTenant()),
        tap((x) => {
            if (x.isFeedbackRequired) {
                this.nextEpisodeLogic();
                this.feedbackRequired = true;
            }
        })
    )

    nextEpisodeLogic(): void {
        this._episodeGuardService.nextEpisode();
    }

    private readonly _streams = merge(
        this._feedBackRequiredForTenant$,
        this._forUrl$,
        this._checkWatchedEpisode$
    )
}
