<info></info>
<div class="info-box">
  <div class="info-box-inner">
    <div class="row ourShow-mail">
      <img class="ourShow-mail-image" src="/assets/common/images/info/Contact-mail.png">
    </div>
    <div class="inquiries">
      <span> Inquiries:&nbsp;</span>
      <a href="mailto: hello@wordplay.media" class="contact__mail">
        hello@wordplay.media
      </a>
    </div>
    <div class="row wordPlay-contact">
      <img class="wordPlay-contact-left" src="/assets/common/images/info/Contact-leftImage.png">
      <img class="wordPlay-contact-right" src="/assets/common/images/info/Contact-rightImage.png">
    </div>
  </div>
</div>
<site-footer></site-footer>