<div class="content-wrapper ">

  <h2>
    PRIVACY NOTICE
  </h2>

  <strong class="forStrong">
    <span>
      Last updated January 13, 2023

    </span>
  </strong>

  <div>
    <span>
      This privacy notice for WordPlay Media Ltd. (<strong>'Company'</strong>, <strong>'we'</strong>,
      <strong>'us'</strong> , or <strong>'our'</strong>), describes how and why we might
      collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as
      when
      you:
    </span>
    <ul>
      <li> Visit our website at <span (click)="goToLink('https://www.wordplay.media')"
          class="forLink">https://www.wordplay.media</span>, or any website of ours that links to this privacy notice
      </li>
      <li>
        Download and use our mobile application (Our Show Games), or any other application of ours that links to this
        privacy notice

      </li>
      <li>
        Engage with us in other related ways, including any sales, marketing, or events

      </li>

    </ul>

    <span>
      <strong>Questions or concerns?</strong>
      Reading this privacy notice will help you understand your privacy rights and choices. If you
      do not agree with our policies and practices, please do not use our Services. If you still have any questions or
      concerns, please contact us at j.karlsen@wordplay.media.
    </span>
  </div>

  <h3>
    SUMMARY OF KEY POINTS
  </h3>

  <div>
    <strong class="forStrong">
      <span class="forItallic">
        This summary provides key points from our privacy notice, but you can find out more details about any of these
        topics by clicking the link following each key point or by using our table of contents below to find the section
        you are looking for. You can also click <span (click)="scrollToForm('tableContent')" class="forLink">here</span>
        to go directly to our table of
        contents.
      </span>
    </strong>
  </div>

  <div>
    <span>
      <strong class="forStrong">
        What personal information do we process?
      </strong>
      When you visit, use, or navigate our Services, we may process personal information depending on how you interact
      with WordPlay Media Ltd. and the Services, the choices you make, and the products and features you use. Click
      <span (click)="scrollToForm('forSection1')" class="forLink">here</span>
      to learn more.
    </span>
  </div>

  <div>
    <span>
      <strong class="forStrong">
        Do we process any sensitive personal information?
      </strong>
      We do not process sensitive personal information.
    </span>
  </div>

  <div>
    <span>
      <strong class="forStrong">
        Do we receive any information from third parties?
      </strong>
      We do not receive any information from third parties.
    </span>
  </div>

  <div>
    <span>
      <strong class="forStrong">
        How do we process your information?
      </strong>
      We process your information to provide, improve, and administer our Services,
      communicate with you, for security and fraud prevention, and to comply with law. We may also process your
      information
      for other purposes with your consent. We process your information only when we have a valid legal reason to do so.
      Click <span (click)="scrollToForm('forSection2')" class="forLink">here</span> to learn more.
    </span>
  </div>

  <div>
    <span>
      <strong class="forStrong">
        In what situations and with which types of parties do we share personal information?
      </strong>
      In what situations and with which types of parties do we share personal information? We may share information in
      specific situations and with specific categories of third parties. Click <span class="forLink">here</span> to
      learn more.
    </span>
  </div>

  <div>
    <span>
      <strong class="forStrong">
        How do we keep your information safe?
      </strong>
      We have organisational and technical processes and procedures in place to
      protect your personal information. However, no electronic transmission over the internet or information storage
      technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or
      modify your information. Click <span class="forLink">here</span> to learn more.
    </span>
  </div>

  <div>
    <span>
      <strong class="forStrong">
        What are your rights?
      </strong>
      Depending on where you are located geographically, the applicable privacy law may mean you have
      certain rights regarding your personal information. Click <span (click)="scrollToForm('forSection8')"
        class="forLink">here</span> to learn more.
    </span>
  </div>


  <div>
    <span>
      <strong class="forStrong">
        How do you exercise your rights?
      </strong>
      The easiest way to exercise your rights is by filling out our data subject request
      form available here: <span (click)="goToLink('https://www.wordplay.media')"
        class="forLink">https://www.wordplay.media</span>, or by contacting us. We will consider and
      act upon any request in
      accordance
      with applicable data protection laws.
    </span>
  </div>

  <div>
    <span>
      Want to learn more about what WordPlay Media Ltd. does with any information we collect? Click <span
        (click)="scrollToForm('tableContent')" class="forLink">here</span> to review the
      notice in full.
    </span>
  </div>


  <h2 id="tableContent">TABLE OF CONTENTS</h2>
  <ul>
    <div (click)="scrollToForm('forSection1')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">1. WHAT INFORMATION
            DO WE COLLECT?</span></a></span></div>
    <div (click)="scrollToForm('forSection2')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">2. HOW DO WE PROCESS
            YOUR INFORMATION?</span></a></span></div>
    <div (click)="scrollToForm('forSection3')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">3. WHAT LEGAL BASES
            DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?</span></a></span></div>
    <div (click)="scrollToForm('forSection4')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">4. WHEN AND WITH WHOM
            DO WE SHARE YOUR PERSONAL
            INFORMATION?</span></a></span></div>
    <div (click)="scrollToForm('forSection5')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">5. DO WE USE COOKIES
            AND OTHER TRACKING TECHNOLOGIES?</span></a></span>
    </div>
    <div (click)="scrollToForm('forSection6')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">6. HOW LONG DO WE
            KEEP YOUR INFORMATION?</span></a></span></div>
    <div (click)="scrollToForm('forSection7')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">7. HOW DO WE KEEP
            YOUR INFORMATION SAFE?</span></a></span></div>

    <div (click)="scrollToForm('forSection8')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">8. WHAT ARE YOUR
            PRIVACY RIGHTS?</span></a></span></div>
    <div (click)="scrollToForm('forSection9')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">9. CONTROLS FOR
            DO-NOT-TRACK FEATURES</span></a></span></div>
    <div (click)="scrollToForm('forSection10')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">10. DO CALIFORNIA
            RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span>
    </div>
    <div (click)="scrollToForm('forSection11')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">11. DO WE MAKE
            UPDATES TO THIS NOTICE?</span></a></span></div>
    <div (click)="scrollToForm('forSection12')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">12. HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE?</span></a></span></div>
    <div (click)="scrollToForm('forSection13')" style="line-height: 1.5; cursor: pointer;"><span
        style="font-size: 15px;"><a data-custom-class="link"><span style="color: rgb(89, 89, 89);">13. HOW CAN YOU
            REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?</span></a></span></div>
  </ul>


  <h2 id="forSection1">1. WHAT INFORMATION DO WE COLLECT?</h2>

  <h3>Personal information you disclose to us</h3>


  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      We collect personal information that you provide to us.
    </span>
  </div>

  <div>
    <span>
      We collect personal information that you voluntarily provide to us when you register on the Services, express an
      interest in obtaining information about us or our products and Services, when you participate in activities on the
      Services, or otherwise when you contact us.
    </span>
  </div>

  <div>
    <strong class="forStrong">Personal Information Provided by You. </strong>
    <span>
      The personal information that we collect depends on the context of your
      interactions with us and the Services, the choices you make, and the products and features you use. The personal
      information we collect may include the following:
    </span>
  </div>

  <ul>
    <li>names</li>
    <li> email addresses</li>
    <li>usernames</li>
    <li>passwords</li>
  </ul>


  <div>
    <strong class="forStrong">Sensitive Information. </strong>
    <span>
      We do not process sensitive information.
    </span>
  </div>

  <div>
    <strong class="forStrong">Application Data.</strong>
    <span>
      If you use our application(s), we also may collect the following information if you choose to
      provide us with access or permission:
    </span>
  </div>


  <ul>
    <li>
      <span class="forItallic">Mobile Device Data.</span>
      We automatically collect device information (such as your mobile device ID, model, and
      manufacturer), operating system, version information and system configuration information, device and application
      identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier,
      and
      Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect
      information
      about the phone network associated with your mobile device, your mobile device’s operating system or platform, the
      type of mobile device you use, your mobile device’s unique device ID, and information about the features of our
      application(s) you accessed.
    </li>
    <li>
      <span class="forItallic">Push Notifications. </span>
      We may request to send you push notifications regarding your account or certain features of the
      application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your
      device's settings.
    </li>
  </ul>


  <div>
    <span>
      This information is primarily needed to maintain the security and operation of our application(s), for
      troubleshooting, and for our internal analytics and reporting purposes.
    </span>
  </div>

  <div>
    <span>
      All personal information that you provide to us must be true, complete, and accurate, and you must notify us of
      any
      changes to such personal information.
    </span>
  </div>

  <h2> Information automatically collected
  </h2>

  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics —
      is collected automatically when you visit our Services.
    </span>
  </div>


  <div>
    <span>
      We automatically collect certain information when you visit, use, or navigate the Services. This information does
      not
      reveal your specific identity (like your name or contact information) but may include device and usage
      information,
      such as your IP address, browser and device characteristics, operating system, language preferences, referring
      URLs,
      device name, country, location, information about how and when you use our Services, and other technical
      information.
      This information is primarily needed to maintain the security and operation of our Services, and for our internal
      analytics and reporting purposes.
    </span>
  </div>

  <div>
    <span>
      Like many businesses, we also collect information through cookies and similar technologies.
    </span>
  </div>

  <div>
    <span>
      The information we collect includes:
    </span>
  </div>
  <div>
    <ul>
      <li>
        <span class="forItallic">Log and Usage Data.</span>
        Log and usage data is service-related, diagnostic, usage, and performance information our
        servers
        automatically collect when you access or use our Services and which we record in log files. Depending on how you
        interact with us, this log data may include your IP address, device information, browser type, and settings and
        information about your activity in the Services (such as the date/time stamps associated with your usage, pages
        and
        files viewed, searches, and other actions you take such as which features you use), device event information
        (such as
        system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
      </li>
      <li>
        <span class="forItallic">Device Data.</span>
        We collect device data such as information about your computer, phone, tablet, or other device you
        use to
        access the Services. Depending on the device used, this device data may include information such as your IP
        address
        (or proxy server), device and application identification numbers, location, browser type, hardware model,
        Internet
        service provider and/or mobile carrier, operating system, and system configuration information.
      </li>
      <li>
        <span class="forItallic">Location Data.</span>
        We collect location data such as information about your device's location, which can be either
        precise
        or imprecise. How much information we collect depends on the type and settings of the device you use to access
        the
        Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your
        current
        location (based on your IP address). You can opt out of allowing us to collect this information either by
        refusing
        access to the information or by disabling your Location setting on your device. However, if you choose to opt
        out, you
        may not be able to use certain aspects of the Services.
      </li>
    </ul>
  </div>

  <div>
    <h2 id="forSection2">
      2. HOW DO WE PROCESS YOUR INFORMATION?
    </h2>
  </div>

  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      We process your information to provide, improve, and administer our Services, communicate with you, for
      security and fraud prevention, and to comply with law. We may also process your information for other purposes
      with
      your consent.
    </span>
  </div>

  <div>
    <strong class="forStrong">
      We process your personal information for a variety of reasons, depending on how you interact with our Services,
      including:

    </strong>
  </div>

  <div>
    <ul>
      <li>
        <strong class="forStrong">To facilitate account creation and authentication and otherwise manage user
          accounts.</strong>
        We may process your information so you can create and log in to your account, as well as keep your account in
        working order.
      </li>
      <li>
        <strong class="forStrong">To request feedback.</strong>
        We may process your information when necessary to request feedback and to contact you about your
        use of our Services.
      </li>
      <li>
        <strong class="forStrong">To identify usage trends.</strong>
        We may process information about how you use our Services to better understand how they are
        being used so we can improve them.
      </li>
      <li>
        <strong class="forStrong">To save or protect an individual's vital interest.</strong>
        We may process your information when necessary to save or protect
        an individual’s vital interest, such as to prevent harm.
      </li>
    </ul>
  </div>

  <h2 id="forSection3"> 3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>

  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      We only process your personal information when we believe it is necessary and we have a valid legal reason
      (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with
      services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate
      business interests.
    </span>
  </div>


  <div>
    <span>
      The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on
      in
      order to process your personal information. As such, we may rely on the following legal bases to process your
      personal
      information:
    </span>
  </div>

  <div>
    <ul>
      <li>
        <strong class="forStrong">
          Consent.
        </strong>
        We may process your information if you have given us permission (i.e. consent) to use your personal
        information for a specific purpose. You can withdraw your consent at any time. Click <span
          (click)="scrollToForm('forSection8')" class="forLink">here</span> to learn more.
      </li>
      <li>
        <strong class="forStrong">
          Legitimate Interests.
        </strong>
        We may process your information when we believe it is reasonably necessary to achieve our
        legitimate business interests and those interests do not outweigh your interests and fundamental rights and
        freedoms.
        For example, we may process your personal information for some of the purposes described in order to:
        <UL>
          <li>
            Analyse how our Services are used so we can improve them to engage and retain users
          </li>
          <li>
            Understand how our users use our products and services so we can improve user experience
          </li>
        </UL>
      </li>
      <li>
        <strong class="forStrong">
          Legal Obligations.
        </strong>
        We may process your information here we believe it is necessary for compliance
        with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or
        defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
      </li>
      <li>
        <strong class="forStrong">
          Vital Interests.
        </strong>
        We may process your information here we believe it is necessary to protect your
        vital interests or
        the vital interests of a third party, such as situations involving potential threats to the safety of any
        person.
      </li>
    </ul>
  </div>


  <div>
    <span>
      In legal terms, we are generally the 'data controller' under European data protection laws of the personal
      information
      described in this privacy notice, since we determine the means and/or purposes of the data processing we perform.
      This
      privacy notice does not apply to the personal information we process as a 'data processor' on behalf of our
      customers.
      In those situations, the customer that we provide services to and with whom we have entered into a data processing
      agreement is the 'data controller' responsible for your personal information, and we merely process your
      information
      on their behalf in accordance with your instructions. If you want to know more about our customers' privacy
      practices,
      you should read their privacy policies and direct any questions you have to them.
    </span>
  </div>


  <h2 id="forSection4">
    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
  </h2>

  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      We may share information in specific situations described in this section and/or with the following
      categories of third parties.
    </span>
  </div>

  <div>
    <strong class="forStrong">
      Vendors, Consultants, and Other Third-Party Service Providers.
    </strong>
    We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who
    perform services for us or on our behalf and require
    access to such information to do that work. The categories of third parties we may share personal information with
    are
    as follows:
    <ul>
      <li>
        Data Analytics Services
      </li>
      <li>
        Cloud Computing Services
      </li>
    </ul>
  </div>

  <div>
    <span>
      We also may need to share your personal information in the following situations:
    </span>
    <ul>
      <li>
        <strong>Business Transfers. </strong>
        We may share or transfer your information in connection with, or during negotiations of, any
        merger, sale of company assets, financing, or acquisition of all or a portion of our business to another
        company.
      </li>
    </ul>
  </div>

  <h2 id="forSection5">
    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
  </h2>

  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      We may use cookies and other tracking technologies to collect and store your information.
    </span>
  </div>

  <div>
    <span>
      We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
      Specific information about how we use such technologies and how you can refuse certain cookies is set out in our
      Cookie Notice.
    </span>
  </div>

  <h2 id="forSection6">
    6. HOW LONG DO WE KEEP YOUR INFORMATION?
  </h2>

  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless
      otherwise required by law.
    </span>
  </div>

  <div>
    <span>
      We will only keep your personal information for as long as it is necessary for the purposes set out in this
      privacy
      notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal
      requirements). No purpose in this notice will require us keeping your personal information for longer than the
      period
      of time in which users have an account with us.
    </span>
  </div>

  <div>
    <span>
      When we have no ongoing legitimate business need to process your personal information, we will either delete or
      anonymise such information, or, if this is not possible (for example, because your personal information has been
      stored in backup archives), then we will securely store your personal information and isolate it from any further
      processing until deletion is possible.
    </span>
  </div>

  <h2 id="forSection7">
    7. HOW DO WE KEEP YOUR INFORMATION SAFE?
  </h2>

  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless
      otherwise required by law.
    </span>
  </div>


  <div>
    <span>
      We have implemented appropriate and reasonable technical and organisational security measures designed to protect
      the
      security of any personal information we process. However, despite our safeguards and efforts to secure your
      information, no electronic transmission over the Internet or information storage technology can be guaranteed to
      be
      100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties
      will
      not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we
      will
      do our best to protect your personal information, transmission of personal information to and from our Services is
      at
      your own risk. You should only access the Services within a secure environment.
    </span>
  </div>

  <h2 id="forSection8">
    8. WHAT ARE YOUR PRIVACY RIGHTS?
  </h2>

  <div>

    <span class="forItallic">
      <strong>In Short: </strong>
      In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that
      allow you greater access to and control over your personal information. You may review, change, or terminate your
      account at any time.
    </span>
  </div>

  <div>
    <span>
      In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may
      include the right (i) to request access and obtain a copy of your personal information, (ii) to request
      rectification
      or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data
      portability. In certain circumstances, you may also have the right to object to the processing of your personal
      information. You can make such a request by contacting us by using the contact details provided in the section
      <span (click)="scrollToForm('forSection11')" class="forLink">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</span>
      below.
    </span>
  </div>

  <div>
    <span>
      We will consider and act upon any request in accordance with applicable data protection laws.
    </span>
  </div>

  <div>
    <span>
      If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you
      also
      have the right to complain to your local data protection supervisory authority. You can find their contact details
      here: <span (click)="goToLink('https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm')"
        class="forLink"> https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span>
    </span>
  </div>

  <div>
    <span>
      If you are located in Switzerland, the contact details for the data protection authorities are available here:
      <a (click)="goToLink('https://www.edoeb.admin.ch/edoeb/en/home.html')" class="forLink">
        https://www.edoeb.admin.ch/edoeb/en/home.html</a>
      .
    </span>
  </div>

  <div>
    <strong class="forStrong">
      Withdrawing your consent:
    </strong>
    <span>
      If we are relying on your consent to process your personal information, you have the right
      to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the
      contact
      details provided in the section <span (click)="scrollToForm('forSection11')" class="forLink">'HOW CAN YOU CONTACT
        US ABOUT THIS NOTICE?'</span> below.
    </span>
  </div>


  <div>
    <span>
      However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, will it
      affect the processing of your personal information conducted in reliance on lawful processing grounds other than
      consent.
    </span>
  </div>

  <h3>
    Account Information
  </h3>

  <div>
    <span>
      If you would at any time like to review or change the information in your account or terminate your account, you
      can:
    </span>
  </div>

  <div>
    <ul>
      <li>
        Log in to your account settings and update your user account.
      </li>
      <li>
        Contact us using the contact information provided.
      </li>
    </ul>
  </div>

  <div>
    <span>
      Upon your request to terminate your account, we will deactivate or delete your account and information from our
      active
      databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist
      with
      any investigations, enforce our legal terms and/or comply with applicable legal requirements.
    </span>
  </div>

  <div>
    <strong style="text-decoration: underline;" class="forStrong">
      Cookies and similar technologies:
    </strong>
    <span>
      Most Web browsers are set to accept cookies by default. If you prefer, you can
      usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or
      reject
      cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising
      by
      advertisers on our Services visit
      <span (click)="goToLink('http://www.aboutads.info/choices/')"
        class="forLink">http://www.aboutads.info/choices/</span>.
    </span>
  </div>

  <div>
    <span>
      If you have questions or comments about your privacy rights, you may email us at help@wordplay.media.
    </span>
  </div>


  <h2 id="forSection9">
    9. CONTROLS FOR DO-NOT-TRACK FEATURES
  </h2>

  <div>
    <span>
      Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature
      or
      setting you can activate to signal your privacy preference not to have data about your online browsing activities
      monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals
      has
      been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that
      automatically
      communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must
      follow in
      the future, we will inform you about that practice in a revised version of this privacy notice.
    </span>
  </div>

  <h2 id="forSection10">
    10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
  </h2>

  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      Yes, if you are a resident of California, you are granted specific rights regarding access to your personal
      information.
    </span>
  </div>

  <div>
    <span>
      California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are
      California
      residents to request and obtain from us, once a year and free of charge, information about categories of personal
      information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of
      all
      third parties with which we shared personal information in the immediately preceding calendar year. If you are a
      California resident and would like to make such a request, please submit your request in writing to us using the
      contact information provided below.
    </span>
  </div>

  <div>
    <span>
      If you are under 18 years of age, reside in California, and have a registered account with Services, you have the
      right to request removal of unwanted data that you publicly post on the Services. To request removal of such data,
      please contact us using the contact information provided below and include the email address associated with your
      account and a statement that you reside in California. We will make sure the data is not publicly displayed on the
      Services, but please be aware that the data may not be completely or comprehensively removed from all our systems
      (e.g. backups, etc.).
    </span>
  </div>


  <h2 id="forSection11">
    11. DO WE MAKE UPDATES TO THIS NOTICE?
  </h2>


  <div>
    <span class="forItallic">
      <strong>In Short: </strong>
      Yes, we will update this notice as necessary to stay compliant with relevant laws.
    </span>
  </div>

  <div>
    <span>
      We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised'
      date and the updated version will be effective as soon as it is accessible. If we make material changes to this
      privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending
      you a
      notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting
      your
      information.
    </span>
  </div>

  <h2 id="forSection12">
    12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
  </h2>

  <div>
    <span>
      If you have questions or comments about this notice, you may email us at help@wordplay.media or by post to:
    </span>
  </div>


  <div>
    <span>
      WordPlay Media Ltd.<br>
      222 Osborne Road<br>
      Newcastle upon Tyne, Tyne and Wear NE2 3LB<br>
      United Kingdom
    </span>
  </div>

  <div>
    <span>
      If you are a resident in the United Kingdom, the 'data controller' of your personal information is WordPlay Media
      Ltd.. WordPlay Media Ltd. has appointed Jonathan Karlsen to be its representative in the UK. You can contact them
      directly regarding the processing of your information by WordPlay Media Ltd., by email at j.karlsen@gmail.com, by
      visiting <span (click)="goToLink('https://www.wordplay.media')" class="forLink">https://www.wordplay.media</span>,
      by phone at +447530428500, or by post to:
    </span>
  </div>


  <div>
    <span>
      222 Osborne Road <br>
      Newcastle upon Tyne, Tyne and Wear NE2 3LB<br>
      England
    </span>
  </div>

  <h2 id="forSection13">
    13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
  </h2>

  <div>
    <span>
      Based on the applicable laws of your country, you may have the right to request access to the personal information
      we
      collect from you, change that information, or delete it. To request to review, update, or delete your personal
      information, please visit: <span (click)="goToLink('https://www.wordplay.media')"
        class="forLink">https://www.wordplay.media</span>.
    </span>
  </div>

</div>