import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Language, LanguageData } from './LanguageConsts';
import { LanguageService } from './language-picker-local.service';
import { map } from 'rxjs/operators';

@Component({
    templateUrl: './language-picker-local.component.html',
    styleUrls: ['./language-picker-local.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'language-picker-local',
})

export class LanguagePickerLocalComponent extends AppComponentBase {

    languages: Language[] = LanguageData.languages;
    imageSrc: string = this.languages[2]?.flagUrl;
    isUserLoggedIn: boolean = true;
    islanguageListShown:boolean=false;
    languagesForDropDown: Language[] = LanguageData.languages.filter((x)=>x.id!==7 && x.id!==8);

    constructor(
        injector: Injector,
        private _languageService: LanguageService,

    ) {
        super(injector)
        this.isUserLoggedIn = abp.session.userId > 0;
    }

    ngOnInit(): void {
        this.tenantLanguageName$.subscribe();
    }

    readonly tenantLanguageName$ = this._languageService.tetantLanguageName$;

    readonly flagUrl$ = this.tenantLanguageName$.pipe(
        map((languageName)=>
        {
                return  this.languages.find((language) => language.name === languageName).flagUrl;
            }
        )
    );

    showLanguageList(){
        this.islanguageListShown=!this.islanguageListShown;
    }
}
