<div [class.header-container-info]="isInInfoPage"
 [class.header-container-ourshow]="isInEpisodesOrGamesOrEpisodePage"
 [class.header-container-episode]="isInEpisodePage" class="header-container">
    <div  [class.header-aboutUs]="isInAboutUsPage"  class="header-blue-box"
     [style.backgroundColor]="backGroundColor"
      [class.header-isInEpisodePage]="isInEpisodePage">
      <h2 style="display: none;" [class.appText]="isInDownload">Download our Android app</h2>

        <div class="header-box">
            <h2 class="header-title" [style.color]="color">
                {{ title}}
            </h2>
        </div>
        <div *ngIf="!isInEpisodePage" class="header-box" >
            <img
            [class.header-image-isInGamesPage]="isInGamesPage"
            [class.header-image-isInDownload]="isInDownload"
            [class.header-image-isInLearnMorePage]="isInLearnMorePage"
            [class.header-image-isInEpisodesPage]="isInEpisodesPage"
            [class.header-image-isInAboutUsPage]="isInAboutUsPage"
            [class.header-image-isInInfoPage]="isInInfoPage"
            [class.header-image]="!isInEpisodesOrGamesOrEpisodePage"
            src="{{ imgSrc }}" />
        </div>
    </div>
</div>
