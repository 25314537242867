import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EpisodeDto, EpisodeOvalsServiceProxy, OvalFilesDto } from '@shared/service-proxies/service-proxies';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Language, LanguageData } from '../../language-picker-local/LanguageConsts';
import { BehaviorSubject } from 'rxjs';


@Component({
    templateUrl: './oval-content-Default.component.html',
    styleUrls: ['./oval-content-Default.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'oval-content-Default',
})
export class OvalContentDefaultComponent extends AppComponentBase {

    episodes: EpisodeDto[] = [];
    languages: Language[] = LanguageData.languages;
    currentEpisodeId = localStorage.getItem("currentEpisode");
    episodeOvalId: number;
    color = "";
    @Input() episodeName: string="";

    constructor(
        injector: Injector,
        private route: ActivatedRoute,
        private _episodeOvalsServiceProxy: EpisodeOvalsServiceProxy,
    ) {
        super(injector);
        this.episodeOvalId = Number(this.route.snapshot.paramMap.get('id'));
    }

    private readonly _filesSubject = new BehaviorSubject<OvalFilesDto>(undefined);
    readonly _episodeOvalFiles$ = this._filesSubject.asObservable().pipe(
        switchMap(() => this._episodeOvalsServiceProxy.getEpisodeOvalFiles(this.episodeOvalId)),
        shareReplay(1)
    );

    readonly _filesWithLevel$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.files),
    );

    readonly _ovalName$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.ovalName)
    );

    readonly _episodeName$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.episodeTitle)
    );

    private readonly _urlSubject = new BehaviorSubject<string>(undefined);
    readonly _url$ = this._urlSubject.asObservable().pipe(
        map((fileGuid) => `${AppConsts.remoteServiceBaseUrl}/File/GetBinaryFile?id=${fileGuid}`),

        tap((url) => {
            window.open(
                url,
                '_blank'
            );
        }
        ));

    openFile(fileGuid: string): void {
        this._urlSubject.next(fileGuid);
        this._url$.pipe(
            take(1),
        ).subscribe();
    }
}



