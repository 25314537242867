
export class Language {
    id:number;
    name: string;
    flagUrl: string;
}

export class LanguageData {
static readonly languages:Language[]=[
    {id:1, name:"Armenian", flagUrl:"/assets/common/images/flags/armenia.svg"}, 
    {id:2, name:"Albanian", flagUrl:"/assets/common/images/flags/albania.png"}, 
    {id:3, name:"Georgian", flagUrl:"/assets/common/images/flags/georgia.jpg"}, 
    {id:4, name:"Spanish (Mexican)", flagUrl:"/assets/common/images/flags/spain.png"}, 
    {id:5, name:"Karen", flagUrl:"/assets/common/images/flags/myanmar.jpg"}, 
    {id:6, name:"Ukrainian", flagUrl:"/assets/common/images/flags/ukrain.png"}, 
    {id:7, name:"German", flagUrl:"/assets/common/images/flags/germany.png"}, 
    {id:8, name:"Norwegian", flagUrl:"/assets/common/images/flags/norway.png"}, 
    {id:9, name:"Lao", flagUrl:"/assets/common/images/flags/lao.png"}, 
    {id:10, name:"Thai", flagUrl:"/assets/common/images/flags/thai.png"}, 
];

static readonly flagSrcs: string[] = [
    "/assets/common/images/flags/GB.jpg",
    "/assets/common/images/flags/armenia.svg",
    "/assets/common/images/flags/albania.png",
    "/assets/common/images/flags/georgia.jpg",
]
}

