<info></info>
<div class="info-box">
  <div class="info-box-inner">
    <div class="platform-media support-faq">
      <h3>FAQ</h3>
      <div class="contact_box">
        <span>Support:&nbsp;</span>
        <a href="mailto: help@wordplay.media" class="contact__mail">
          help@wordplay.media
        </a>
      </div>
    </div>
    <div class="row wordPlay-support-images">
      <img id="support_Left_Image" src="/assets/common/images/info/Support_Left_Image.png">
      <img id="support_Center_Image" src="/assets/common/images/info/Support_Center_Image.png">
      <img id="support_Right_Image" src="/assets/common/images/info/Support_Right_Image.png">
    </div>
  </div>
</div>

<site-footer></site-footer>