<main [style.background-color]="color" id="forMainLM3" class="main-container">
    <siteHeader title="Learn More" imgSrc="/assets/common/images/learnMore/MillieWithFlower.png"
        backGroundColor="#D03E97" color="#F8E031"></siteHeader>
    <div class="LM3-body">
        <div class="LM3-oval-episode row">
            <div *ngIf="_ovalName$ | async as ovalName" class="LM3-oval col-3">
                <oval color="#E9B6D4" borderColor="#95189B" textColor="black" description="{{ovalName}}"></oval>
            </div>
            <div class="LM3-episode col-5">
                <h1>{{episodeName}}
                </h1>
            </div>
        </div>
        <div class="LM3-files row">
            <div *ngIf="(_filesWithLevelE0$ | async)?.length>0" class="LM3-files-E0 col-4">            
                <div   class="E0-files">
                    <img class="zero" src="/assets/common/images/ovals/LM3_zero.png" alt="">
                    <img class="E0" src="/assets/common/images/ovals/LM3_Level0.png" alt="">

                    <ul>
                        <li *ngFor="let fileWithLevelE0 of _filesWithLevelE0$ | async">
                            <div class="fileName">
                                <a (click)="openFile(fileWithLevelE0?.file.id)" target="_blank"> {{fileWithLevelE0.file.nameWithoutExtention}}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div *ngIf="(_filesWithLevelE1$ | async)?.length>0"  class="LM3-files-E1 col-4">
                <div class="E1-files">
                    <img class="one" src="/assets/common/images/ovals/LM3_one.png" alt="">
                    <img class="E1" src="/assets/common/images/ovals/LM3_Level1.png" alt="">                    <ul>
                        <li *ngFor="let fileWithLevelE1 of _filesWithLevelE1$ | async">
                            <div class="fileName">
                                <a (click)="openFile(fileWithLevelE1?.file.id)" target="_blank"> {{fileWithLevelE1.file.nameWithoutExtention}}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div  *ngIf="(_filesWithLevelE2$ | async)?.length>0"  class="LM3-files-E2 col-4">
                    <div class="E2-files">
                        <img class="two" src="/assets/common/images/ovals/LM3_two.png" alt="">
                        <img class="E2" src="/assets/common/images/ovals/LM3_Level2.png" alt="">                        <ul>
                            <li *ngFor="let fileWithLevelE2 of _filesWithLevelE2$ | async">
                                <div class="fileName">
                                    <a (click)="openFile(fileWithLevelE2?.file.id)" target="_blank"> {{fileWithLevelE2.file.nameWithoutExtention}}</a>
                                </div>
                            </li>
                        </ul>
                </div>
            </div>
        </div>

        <div class="LM3-images row">
            <img class="Harry" src="/assets/common/images/ovals/Harry.png" alt="">
            <img class="Tree" src="/assets/common/images/ovals/{{TreeMobile}}.png" alt="">
        </div>
    </div>
</main>
<site-footer color="#b682eb"></site-footer>
