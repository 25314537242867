import { Component, Injector, Renderer2, ViewEncapsulation } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { LanguageService } from '../language-picker-local/language-picker-local.service';

@Component({
    templateUrl: './site-nav.component.html',
    styleUrls: ['./site-nav.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'site-nav',
})
export class SiteNavbarComponent extends AppComponentBase {

    burgerSrc: string = "/assets/images/icons/burger.png";
    myLinksId: string = "myLinksNone";
    userName: string = this.appSession.user?.userName;
    isUserLoggedIn: boolean = true;
    showPicker: boolean = false;
    menuBtnClick: boolean = false;
    
    constructor(
        injector: Injector,
        private _authService: AppAuthService,
        private _languageService: LanguageService,
        private renderer: Renderer2,

    ) {
        super(injector);
        this.isUserLoggedIn = abp.session.userId > 0;
    }

    ngOnInit(){
        this.removeBurgerMenu();    
    }

    toggleBurger(): void {
        if (this.myLinksId === "myLinks") {
            this.myLinksId = "myLinksNone";
        }
        else {
            this.myLinksId = "myLinks";
        }
    }

    removeBurgerMenu(){
        this.renderer.listen('window', 'click', (e: HTMLElementEvent<HTMLButtonElement>) => {
            
            var classList=e.target.classList;
            
            if(!classList.contains("ng-tns-c606-2") && !classList.contains("navbar-burger"))
            {
                this.myLinksId = "myLinksNone";
            }
        });

        type HTMLElementEvent<T extends HTMLElement> = Event & {
            target: T;
        }
    }

    logout(): void {
        this._authService.logout();
    }
}
