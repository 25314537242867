<main [style.background-color]="color" id="forMainLM6" class="main-container">
  <siteHeader title="Learn More" imgSrc="/assets/common/images/learnMore/MillieWithFlower.png" backGroundColor="#D03E97"
    color="#F8E031"></siteHeader>
  <div class="LM6-body">
    <div class="LM6-oval-episode row">
      <div *ngIf="_ovalName$ | async as ovalName" class="LM6-oval col-3">
        <oval color="#F19A89" borderColor="#DD1E1A" textColor="#000000" description="{{ovalName}}"></oval>
      </div>
      <div class="LM6-episode col-5">
        <h1>{{episodeName}}
        </h1>
      </div>
    </div>
    <div class="LM6-files row">
      <div class="LM6-Teacher col-5">
        <img src="/assets/common/images/ovals/LM6-Teacher.png" alt="">
      </div>
      <div class=" LM6-files-div col-7">
        <div *ngFor="let fileWithLevel of _filesWithLevel$ | async" class="LM6-file col-4">
          <img src="/assets/common/images/ovals/LM6-Pen.png" alt="">
          <a (click)="openFile(fileWithLevel?.file.id)" class="ovalFileName" target="_blank">
            {{fileWithLevel?.file.nameWithoutExtention}}</a>
        </div>
      </div>
    </div>
  </div>
</main>
<site-footer color="#b682eb"></site-footer>