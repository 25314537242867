import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';


@Component({
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'siteHeader',
})
export class HeaderComponent extends AppComponentBase {

    @Input() imgSrc: string="";
    @Input() title: string="";
    @Input() backGroundColor: string="#6c8df9";
    @Input() color: string="#ffcd2e";


    isInEpisodesOrGamesOrEpisodePage:boolean=false;
    isInEpisodesPage:boolean=false;
    isInGamesPage:boolean=false;
    isInDownload:boolean=false;
    isInEpisodePage:boolean=false;
    isInLearnMorePage:boolean=false;
    isInInfoPage:boolean=false;
    isInAboutUsPage:boolean=false;

    constructor(
        injector: Injector,
        private _router: Router,
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        this.PageDetermination();
        this.AboutPageHeaderChanges();
    }
    
    AboutPageHeaderChanges(){
        if (this.isInAboutUsPage) {
            this.color="#364CB1";
            this.title="About Us";
            this.imgSrc="/assets/common/images/info/AboutUsOurShowHeader.png";
        }       
    }

    PageDetermination(){
        this.isInEpisodesPage = (this._router.url.includes("/episodes"));
        this.isInGamesPage = (this._router.url.includes("/games"));
        this.isInDownload = (this._router.url.includes("/url-download-page"));
        this.isInEpisodePage = (this._router.url.includes("/episode/"));
        this.isInLearnMorePage = (this._router.url.includes("/learn-more") || this._router.url.includes("oval"));
        this.isInInfoPage = (this._router.url.includes("/info"));
        this.isInAboutUsPage = (this._router.url.includes("aboutOurshow") || this._router.url.includes("aboutMedia"));
        this.isInEpisodesOrGamesOrEpisodePage = (this.isInDownload || this.isInEpisodesPage || this.isInGamesPage || this.isInEpisodePage || this.isInLearnMorePage);
    }
}