<div [class.mainOfLearnMore_Dropdown]="isEpisodesDropdownOpened" id="mainOfLearnMore">
  <siteHeader title="Learn More" imgSrc="/assets/common/images/learnMore/MillieWithFlower.png" backGroundColor="#D03E97"
    color="#F8E031"></siteHeader>
  <div class="forDropDownAndOvals">
    <div class="dropDownGeorge">
      <div class="george">
        <img class="cloud" src="/assets/common/images/learnMore/Cloud.png" alt="">
        <img class="georgeImg" src="/assets/common/images/learnMore/George.png" alt="">
        <img class="circle" src="/assets/common/images/learnMore/WhiteCircle.png" alt="">
        <h2 class="textInCould">Select an episode!</h2>
      </div>
      <div class="dropDown">
        <h1 class="textForLearnMore">Use the ovals to "Learn More". Here you can find the script & translation, words, phrases, worksheets, spelling exercises, grammar topics & practice, songs and much more.
        </h1>
        <p-dropdown id="pDropdown" #pDropdown
                    (onHide)="onDropdownClosed()"
                    (onShow)="onDropdownOpened()"
                    (onChange)="onDropdownChange($event)"
                    [options]="episodes"
                    [(ngModel)]="selectedEpisode"
                    optionLabel="title">
        </p-dropdown>
        <span class="forBackGround">
        </span>
        <img class="forArrow" src="/assets/common/images/learnMore/arrow.png" alt="">
      </div>
    </div>
    <div class="ovalsAndDog">
      <div class="dog">
        <img class="dogImg" src="/assets/common/images/learnMore/DogLearnMore.png" alt="">
      </div>
      <div class="ovals">
        <div *ngFor="let episodeOval of episodeOvals$ | async"
             [routerLink]="['/app/oval-content', episodeOval.episodeOvalId]"
             class="learn-more_content-box">
          <oval color="{{episodeOval?.ovalContent.backgroundColor}}"
          borderColor="{{episodeOval?.ovalContent.borderColor}}" textColor="{{episodeOval.ovalContent.textColor}}"
          description="{{episodeOval?.ovalContent.title}}"></oval>
        </div>
      </div>
    </div>
  </div>
</div>
<site-footer color="#FFCD2E"></site-footer>
