<main [style.background-color]="color" id="forMainLM1" class="main-container">
  <siteHeader title="Learn More" imgSrc="/assets/common/images/learnMore/MillieWithFlower.png" backGroundColor="#D03E97"
    color="#F8E031"></siteHeader>
  <div class="LM1-body">
    <div class="LM1-oval-episode row">
      <div *ngIf="_ovalName$ | async as ovalName" class="LM1-oval col-3">
        <oval color="#D4F4B0" borderColor="#44A400" textColor="black" description="{{ovalName}}"></oval>
      </div>
      <div class="LM1-episode col-5">
        <h1>{{episodeName}}
        </h1>
      </div>
      <div class="LM1-bird col-4">
        <img src="/assets/common/images/ovals/LM1_bird.png" alt="">
      </div>
    </div>
    <div class="LM1-book row">
      <div class="LM1-book1 col-2">
        <img src="/assets/common/images/ovals/LM1_book1.png" alt="">
      </div>
      <div class="LM1-book2 col-4">
        <img src="/assets/common/images/ovals/LM1_book2.png" alt="">
      </div>
      <div class="LM1-file col-4">
        <div *ngFor="let fileWithLevel of _filesWithLevel$ | async" >
          <img src="/assets/common/images/ovals/LM1_episode_image.png" alt="">
          <a (click)="openFile(fileWithLevel?.file.id)" class="ovalFileName" target="_blank">
            {{fileWithLevel?.file.nameWithoutExtention}}</a>
        </div>
        </div>
      <div class="LM1-book3 col-2">
        <img src="/assets/common/images/ovals/LM1_book3.png" alt="">
      </div>
    </div>
  </div>
</main>
<site-footer color="#b682eb"></site-footer>