import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { EpisodeDto, EpisodeOvalsServiceProxy, OvalFilesDto } from '@shared/service-proxies/service-proxies';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Language, LanguageData } from '../../language-picker-local/LanguageConsts';
import { BehaviorSubject } from 'rxjs';
import { DifficultyLevel } from '../difficultyLevels';


@Component({
    templateUrl: './oval-content-LM2.component.html',
    styleUrls: ['./oval-content-LM2.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'oval-content-LM2',
})
export class OvalContentLM2Component extends AppComponentBase {

    episodes: EpisodeDto[] = [];
    languages: Language[] = LanguageData.languages;
    currentEpisodeId = localStorage.getItem("currentEpisode");
    episodeOvalId: number;
    color = "";
    LM2_Sun="LM2_Sun"
    isMobileVersion: boolean = false;
    screenWidth: number = window.innerWidth;

    LM2_E0:string="LM2-E0_desktop";
    LM2_E1:string="LM2-E1_desktop";
    LM2_E2:string="LM2-E2_desktop";

    mobileVersionWidth: number = 575.98;
    @Input() episodeName: string="";

    constructor(
        injector: Injector,
        private route: ActivatedRoute,
        private _episodeOvalsServiceProxy: EpisodeOvalsServiceProxy,
    ) {
        super(injector);
        this.episodeOvalId = Number(this.route.snapshot.paramMap.get('id'));
    }

    private readonly _filesSubject = new BehaviorSubject<OvalFilesDto>(undefined);
    readonly _episodeOvalFiles$ = this._filesSubject.asObservable().pipe(
        switchMap(() => this._episodeOvalsServiceProxy.getEpisodeOvalFiles(this.episodeOvalId)),
        shareReplay(1)
    );

    readonly _filesWithLevel$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.files),
    );

    readonly _filesWithLevelE0$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.files.filter((file)=>file.difficultyLevel.name==DifficultyLevel.E0)),
    );

    readonly _filesWithLevelE1$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.files.filter((file)=>file.difficultyLevel.name==DifficultyLevel.E1)),
    );

    readonly _filesWithLevelE2$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.files.filter((file)=>file.difficultyLevel.name==DifficultyLevel.E2)),
    );

    readonly _ovalName$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.ovalName)
    );

    readonly _episodeName$ = this._episodeOvalFiles$.pipe(
        map((ovalFiles) => ovalFiles.episodeTitle)
    );

    private readonly _urlSubject = new BehaviorSubject<string>(undefined);
    readonly _url$ = this._urlSubject.asObservable().pipe(
        map((fileGuid) => `${AppConsts.remoteServiceBaseUrl}/File/GetBinaryFile?id=${fileGuid}`),

        tap((url) => {
            window.open(
                url,
                '_blank'
            );
        }
        ));

    openFile(fileGuid: string): void {
        this._urlSubject.next(fileGuid);
        this._url$.pipe(
            take(1),
        ).subscribe();
    }

    private _mobileVersionCheck() {
        this.isMobileVersion = this.screenWidth <= this.mobileVersionWidth;
        if (this.isMobileVersion) {
            this.LM2_Sun="LM2_Sun_Mobile";
            this.filesImagesLM2();
        }
    }

    ngOnInit(): void {
        this._mobileVersionCheck();
    }

    filesImagesLM2():void{
        this.LM2_E0="LM2-E0";
        this.LM2_E1="LM2-E1";
        this.LM2_E2="LM2-E2";
    }
}