<div *ngIf="isUserLoggedIn" class="language_picker-container">
  <img *ngIf="flagUrl$ | async as flagUrl" src={{flagUrl}} alt="flag" class="dropdown-flag">
  <span class="forLanguageName">{{tenantLanguageName$ | async}}</span>
</div>

<div style="display: flex; width: 70px;" *ngIf="!isUserLoggedIn" class="language_picker-container"
  (click)="showLanguageList()">
  <h6 style="font-size: 11px; color: #071d64;">Languages</h6>
  <img *ngIf="islanguageListShown" class="language_picker-arrow_down" />
  <img *ngIf="!islanguageListShown" class="language_picker-arrow_up" />
</div>
<div class="avalableLanguages" *ngIf="islanguageListShown">
  <div class="avalableLanguage" *ngFor="let language of languagesForDropDown">
    <img src={{language.flagUrl}} alt="flag" class="language-country-flag">
    <div style="font-size: 11px; color: #071d64; display: flex;
      justify-content: center;
      align-items: center;">{{language.name}}</div>
  </div>
</div>