import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map as _map, filter as _filter } from 'lodash-es';

@Injectable()
export class AboutUsService {

    constructor(
        private _router: Router,
    ) {

    }

    AboutUsSlider() {
        var isInAboutOurshow = (this._router.url.includes("aboutOurshow"));
        if (isInAboutOurshow) {
            this._router.navigate(['./app/info/aboutMedia']);
            return;
        }
        this._router.navigate(['./app/info/aboutOurshow']);
    }
}
