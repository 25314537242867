<info></info>

<div class="about_us_media">
    <div id="leftArrow" class="arrow">
        <img (click)="AboutUsSlider()" class="arrow-img" src="/assets/common/images/info/LeftArrow.png" alt="leftArrow">
    </div>
    <div class="platform-media">
        <h4 class="showAndMedia">
            WordPlay Media
        </h4>
        <p>
            Based in the UK, WordPlay was founded with one goal in mind: to give kids across the planet an easy, fun, and
            effective way to learn English, with support in their mother-tongue.
        </p>
        <p>In doing this, we aim to support the UN’s Sustainable Development Goal #4:</p>
    
        <p>
            <span class="Itallic">“Inclusive and equitable quality education opportunities for all.”</span>
        </p>
        <p>
            WordPlay have worked with the Applied Linguistics department of Edinburgh University, and with Northumbria
            University’s School of Design, to develop a ground breaking platform.
        </p>
        <p>
            Using animation, games, outstanding additional learning materials, and instruction and narration in any mother
            tongue (on request), kids worldwide can learn English and have fun.
        </p>
        <p>
            Whether they’re working on their own, or with the guidance of a parent or teacher, the support materials in
            their mother tongue mean that they can learn English as a Second Language, in a way that they find fun and
            rewarding.
        </p>
    
        <h3 class="asHeading">
            "When children are interested, learning happens"
        </h3>
    
        <p>WordPlay have received funding from:</p>
        <p>
            NorthStar Ventures, European Union Regional Development Fund (ERDF), European Investment Bank (EIB), European
            Regional Growth Fund, UK Research & Innovation, Innovate UK, Northern Powerhouse’s Creative Fuse North East, and
            additional funding from Angel Investors.
        </p>

        <div routerLink="/app/info/aboutUs" class="close-button-div">
            <img src="/assets/common/images/info/AboutUsClose.png" alt="closeButton">
        </div>
    </div>
    <div id="rightArrow" class="arrow">
        <img (click)="AboutUsSlider()" class="arrow-img" src="/assets/common/images/info/RightArrow.png" alt="leftArrow">
    </div>
</div>

<site-footer></site-footer>