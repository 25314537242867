import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GameDto, GamesData } from './GamesData';

@Component({
    templateUrl: './games.component.html',
    styleUrls: ['./games.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'games',
})

export class GamesComponent extends AppComponentBase {
    
    games: GameDto[] = GamesData.games;
    videoPlay: boolean = false;
    LetsPlaySrc: string = "LetsPlay";
    ABC: string = "ABC";
    Mobile: string = "Mobile";
    GamesHeader: string = "Games_Header";
    screenWidth: number = window.innerWidth;
    isMobileVersion: boolean = false;
    mobileVersionWidth: number = 575.98;

    constructor(

        injector: Injector,

    ) {
        super(injector)
    }

    ngOnInit(): void {
        this.mobileVersionCheck();
    }

    ngOnDestroy(): void {
        this.games.forEach(element => {
            element.showVideo = false;
        });
    }

    mobileVersionCheck() {
        var isInMobileScreenSize = this.screenWidth <= this.mobileVersionWidth;
        if (isInMobileScreenSize) {
            this.LetsPlaySrc = "LetsPlayMobile";
            this.ABC = "ABCMobile";
            this.Mobile = "MobileMobile";
            this.GamesHeader = "Games_Header_Mobile";
        }
    }

    goDownload(): void {
        window.open(
            `https://wordplay.media/download`,
        );
    }

    toggleVideo(id: number): void {
        var currentGame = this.games.find((game) => game.id === id);
        currentGame.showVideo = !currentGame.showVideo;
        if (currentGame.showVideo == true)
            this.games.filter((game) => game.id !== id).map((item) => {
                item.showVideo = false;
            })
    }
}
