import { Component, Injector, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppUrlForLanguageDto, StorageServiceServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    templateUrl: './downloadPage.component.html',
    styleUrls: ['./downloadPage.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'downloadPage',
})

export class DownloadPageComponent extends AppComponentBase{
    GamesHeader: string = "Games_Header";
    urls: AppUrlForLanguageDto[];

    constructor(
        injector: Injector,
        private _storageService: StorageServiceServiceProxy,
        ) {
        super(injector);
        this._storageService.getAppUrls();
    }

    ngOnInit(): void {
        
    };

    readonly _urlsOfLanguages$=this._storageService.getAppUrls();
}