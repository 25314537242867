import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { map as _map, filter as _filter } from 'lodash-es';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs';

@Injectable()
export class RouteService {

  constructor(
    private router: Router,
    private viewPortScroller: ViewportScroller
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => this.viewPortScroller.scrollToPosition([0, 0]));
  }
}