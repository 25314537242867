<main class="mainOfGamesWithOutVideo">
  <siteHeader title="Games" imgSrc="/assets/common/images/games/{{GamesHeader}}.png" backGroundColor="#5469DE"
    color="#F8E031"></siteHeader>
  <div class="forAllGamesWithOutVideo">
    <div class="gamesImageDiv">
      <div class="gamesImageDownloadDiv">
        <img [routerLink]="['/app/url-download-page']" class="gamesImageDownload" src="/assets/common/images/games/DownloadButtonn.png">
      </div>
      <div class="gamesImageLetsPlayDiv">
        <img class="gamesImageLetsPlay" src="/assets/common/images/games/{{LetsPlaySrc}}.png">
      </div>
    </div>
    <div class="gamesImageDiv">
      <img class="gamesImage" src="/assets/common/images/games/{{Mobile}}.png">
    </div>
    <div class="gamesImageDiv" id="gamesImageDivABC">
      <img class="gamesImageAbc" src="/assets/common/images/games/{{ABC}}.png">
    </div>
  </div>
</main>
<site-footer color="#FFCD2E"></site-footer>