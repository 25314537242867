import { Component, Injector, Renderer2, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';


@Component({
    templateUrl: './home.component.html?v=${new Date().getTime()}',
    styleUrls: ['./home.component.less?v=${new Date().getTime()}'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'home-page',
})
export class HomeComponent extends AppComponentBase {

    windowWidth=window.innerWidth;
    ourShowImageDesktop:string='/assets/common/images/home/ourShow_desktop.png';
    ourShowImageMobile = '/assets/common/images/home/ourShow_mobile.png';
    ourShowImage:string;


    constructor(
        injector: Injector) {
        super(injector);

        if (this.windowWidth<600) {
            this.ourShowImage=this.ourShowImageMobile;
        }
        else{
            this.ourShowImage=this.ourShowImageDesktop; 
        }
    }

}
