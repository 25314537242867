import { ChangeDetectorRef, Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DateTime } from 'luxon';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { map, tap, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
    templateUrl: './site-theme.component.html',
    styleUrls: ['./site-theme.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    selector: 'site-layout',
})
export class SiteLayoutComponent extends AppComponentBase implements OnInit {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    readStateFilter = 'ALL';
    dateRange: DateTime[] = [this._dateTimeService.getStartOfDay(), this._dateTimeService.getEndOfDay()];
    loading = false;

    constructor(
        injector: Injector,
        private changeDetectorRef: ChangeDetectorRef,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    get logs(): any[] {
        return window['_logs'];
    }

    private _showLogsTapCount = 0;
    private _showLogsSubject = new Subject();
    readonly showLogs$ = this._showLogsSubject.asObservable().pipe(
        tap(() => ++this._showLogsTapCount),
        map(() => this._showLogsTapCount >= 5),
        distinctUntilChanged(),
        filter(show => show),
        tap(() => {
            let _logsDate = window['_logsDate'];
            this.changeDetectorRef.detectChanges();
            
            setInterval(() => {
                const logsDate = window['_logsDate'];
                if (_logsDate == logsDate) {
                    return;
                }

                _logsDate = logsDate;
                this.changeDetectorRef.detectChanges();
            }, 2000);

            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 1000);
        })
    );

    copyLogs(): void {
        const logs = document.getElementById('console-logs');
        this.copyToClipboard(logs.innerText);
    }

    private copyToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
    };


    ngOnInit(): void {
        var invervalId = setInterval(() => {
            var element = <HTMLElement>document.querySelector("#footerHarry");
            if (!element) {
                return;
            }
            clearInterval(invervalId);
            element.onclick = () => {
                this._showLogsSubject.next(void 0);
            };
        }, 1000);
    }
}
